import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom'
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useEffect } from 'react';
import axios from 'axios';
import { config } from '../Configuration/Api';
function WhatIsNew({ leftSideTitle, type }) {

    const [list, setlist] = useState([])
    const [loading, setLoading] = useState(true);
    const [hover, setHover] = useState('')
    const width = window.innerWidth;
    const navigate = useNavigate()


    const filterData = (data) => {
        if (type == 'all') {
            setlist(data)
            setLoading(false)
        }
        else if (type == "Man") {

            const specificTypeData = data?.filter((item) => item.general_category.Name == type);
            setlist(specificTypeData)
            setLoading(false)
        }
        else if (type == "Women") {

            const specificTypeData = data?.filter((item) => item.general_category.Name == type);
            setlist(specificTypeData)
            setLoading(false)
        }
    }

    useEffect(() => {

        axios.get(`${config.baseurl}/stitching/main/all`)
            .then(data => {
                filterData(data.data.Response)
            }
            )
            .catch(error => console.log(error));

    }, [])
    console.log("width", parseInt(width / 150));
    return (
        <div>
            <div style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "2%",
                // backgroundColor: "black"
            }}>
                <div style={{
                    // width: "20%",

                }}>
                    <p style={{

                        textTransform: "uppercase",
                        fontWeight: 400,
                        paddingTop: 50,
                        paddingLeft: window.innerWidth > 450 ? 30 : 0,
                        fontSize: "3vh",
                        textAlign: "center",
                        letterSpacing: 2,
                    }}>
                        {/* What's<br />New */}
                        {leftSideTitle}
                    </p>
                </div>

                <div style={{
                    // flex: 10,
                    width: "100%",
                    // backgroundColor: "red"
                    // flexDirection:"row"
                    overflow: "hidden"
                }}>
                    <Swiper
                        spaceBetween={15}
                        // centeredSlides={true}
                        watchSlidesProgress={true}
                        slidesPerView={(width / 200)}
                        pagination={{
                            clickable: true,
                        }}

                    >

                        {list.map((item, index) => {
                            // console.log(item);
                            return (

                                <SwiperSlide style={{

                                }} onClick={() => {
                                    navigate(`/stitching/collection/${item.Name}`, { state: { type: item.general_category.Name } })
                                }}>
                                    <div style={{
                                        width: 150,
                                        // backgroundColor: "pink"
                                    }} onMouseOver={() => {
                                        setHover(item.Name)
                                    }} onMouseOut={() => {
                                        setHover("")
                                    }}>
                                        <img
                                            src={item.Poster} style={{
                                                borderRadius: 80,
                                                width: "100%",
                                                height: 150,
                                                opacity: hover === item.Name ? 0.5 : 1
                                            }}

                                        />
                                        <p style={{

                                            textTransform: "capitalize",
                                            fontSize: 16,
                                            paddingTop: 10,
                                            fontWeight: hover === item.Name ? "bold" : 400,
                                            textAlign: "center",


                                        }}>
                                            {item.Name}
                                        </p>
                                    </div>




                                </SwiperSlide>
                            )
                        }
                        )}



                    </Swiper>
                </div>

            </div>
        </div>
    )
}

export default WhatIsNew