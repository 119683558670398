import React, { useEffect, useState } from 'react'
import logo from "../../Assests/Images/webLogo.svg"
import "./Style.css"
import btnImage from "../../Assests/Images/barsLogo.svg"
import phoneImage from "../../Assests/Images/phone.svg"
import contactImage from "../../Assests/Images/contact.svg"
function TopNavbarrHeader() {

    // const navigate = useNavigate()
    const [search, setSearch] = useState("")
    function onSearch(e) {
        setSearch(e.target.value)
    }
    const showModal = () => {
        if (!search) {
            alert("Please write someting into search!")
            return
        }

        window.location.href = `/search-results?query=${search}`
    }

    return (
        <div className='header'>
            <div>
                <div className='headerRow' >
                    <div className=' logoBox' >
                        <div className='headerMobileBtn'>
                            <h1
                                data-bs-toggle="offcanvas" href="#offcanvasExample" role="button"
                                aria-controls="offcanvasExample" className='mobileLogo'>
                                <img src={btnImage} width={"110%"} height={60} />
                            </h1>
                        </div>
                        <a href="/" className='logo'>
                            <img src={logo} className='logoImage' />
                        </a>
                    </div>

                    <div className=' row  searchBox'>
                        <input data-input-search=""
                            autocomplete="off" type="text"
                            placeholder="FIND YOUR FAVOURITES"
                            className='search col-8'
                            onChange={onSearch}
                        />
                        <p className='col-3 searchBtn'
                            onClick={showModal}>
                            search
                        </p>
                    </div>


                </div>




                <div className='list '>
                    <ul >
                        <li className='listUL'>
                            <a href="/">Home</a>
                        </li>
                        <li className='listUL' >
                            <a href="/stitching-design/Women">Women</a>
                        </li>
                        <li className='listUL'>
                            <a href="/stitching-design/Man">Man</a>
                        </li>
                        <li className='listUL'>
                            <a href="/stitching-design/kids">Kids</a>
                        </li>
                        <li className='listUL'>
                            <a href="/order/history">Orders</a>
                        </li>
                    </ul>
                </div>


                <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offcanvasExampleLabel">
                    <div class="offcanvas-header">
                        <h5 class="offcanvas-title" id="offcanvasExampleLabel">
                            <img src={logo} className='logoImage' />
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="offcanvas-body">
                        <div className='row '>
                            <div className='col-5 border text-center m-2 ' >
                                <img src={phoneImage} width={"50%"} height={"50%"} className='mt-3' />
                                <p className="text-center fw-bold fs-4 text-capitalize ">
                                    <a href="tel://+92 326 432 1272" className='text-decoration-none text-dark '>call us!</a>
                                </p>
                            </div>
                            <div className='col-5 border text-center m-2 ' >
                                <img src={contactImage} width={"50%"} height={"50%"} className='mt-3' />
                                <p className="text-center fw-bold fs-4 text-capitalize text-dark">

                                    <a href="/pages/contact" className='text-decoration-none text-danger '> Contact us</a>
                                </p>
                            </div>
                        </div>

                        <div>
                            <p className='text-light fw-bold fs-1 mt-3 bg-dark p-1'>
                                Menu
                            </p>
                            <div>

                                <p className='text-dark fw-bold fs-3 border p-2 '>
                                    <a href="/" className='text-decoration-none text-dark w-1'>
                                        Home
                                    </a>
                                </p>
                                <p className=' fw-bold fs-3 border p-2'  >
                                    <a href="/stitching-design/Women" className='text-decoration-none text-dark w-1'>  Woman's  </a>
                                </p>
                                <p className='text-dark fw-bold fs-3 border p-2'  >
                                    <a href="/stitching-design/Man" className='text-decoration-none text-dark w-1'>  Man's  </a>
                                </p>
                                <p className='text-dark fw-bold fs-3 border p-2'  >
                                    <a href="/stitching-design/kids" className='text-decoration-none text-dark w-1'> Kid's</a>
                                </p>
                                <p className='text-dark fw-bold fs-3 border p-2'  >
                                    <a href="/order/history" className='text-decoration-none text-dark w-1'> Orders </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}

export default TopNavbarrHeader

