
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import Home from './Screens/Home';
import SpecificProductScreen from './Screens/SpecificProductScreen';
import StitchingOrderScreen from './Screens/StitchingOrderScreen';
import { ViewDesignScreen } from './Screens/ViewDesignScreen';
import Exchange from './Pages/Exchange';
import Contact from './Pages/Contact';
import AboutUs from './Pages/AboutUs';
import OrderTracking from './Screens/OrderTracking';
import StitchingCollection from './Screens/StitchingCollection';
import OrdersRecord from './Screens/OrdersRecord';
import TopNavbarrHeader from './Components/Header/TopNavbarrHeader';
import FooterTest from './Components/FooterTest';
import CustomerReview from './Components/Reviews/CustomerReview';
import FAQ from './Pages/FAQ';
import whatsImgMobile from './Assests/Images/whatsAppMobile.PNG'
import whatsImgWeb from './Assests/Images/WhatsAppWeb.png'
import PrivacyPolicy from './Pages/PrivacyPolicy'
import { useEffect } from 'react';
import SpecialRequestPage from './Pages/SpecialRequestPage';
import FashionWiseApp from './Pages/FashionWiseApp';
import SearchResults from './Screens/SearchResults';
import Blogs from './Screens/Blogs';
import BlogView from './Screens/BlogView';
function App() {

  // const navigate = useNavigate()
  // const askToken = () => {

  //   // const isPermitted = Notification.permission();
  //   // console.log("dadas",isPermitted);
  //   if (!("Notification" in window)) {
  //     alert("This browser does not support notifications")
  //   } else if (Notification.permission === "granted") {
  //     console.log("granted");
  //     new Notification("Hey ")
  //   } else if (Notification.permission != "granted") {
  //     console.log("not granted yet");
  //     Notification.requestPermission()
  //   }
  // }
  // useEffect(() => {

  //   askToken()
  // })
  return (
    <div className="App" style={{
      backgroundColor: "white",
    }}>
      
      <TopNavbarrHeader />

      <BrowserRouter >
        <Routes >
          <Route path='/' element={<Home />} />
          <Route path='/stitching-design/:type' element={<ViewDesignScreen />} />
          <Route path='/stitching/collection/:ctg' element={<StitchingCollection />} />
          <Route path='/search-results' element={<SearchResults />} />
          <Route path='/specific' element={<SpecificProductScreen />} />

          <Route path={`/order/:id`} element={<StitchingOrderScreen />} />
          <Route path='/orders/tracking' element={<OrderTracking />} />
          <Route path='/order/history' element={<OrdersRecord />} />

          {/* contact , about  , reviews , faq */}
          <Route path='/pages/exchange' element={<Exchange />} />
          <Route path='/pages/contact' element={<Contact />} />
          <Route path='/pages/about_us' element={<AboutUs />} />
          <Route path='/pages/customer/reviews' element={<CustomerReview />} />
          <Route path='/pages/faqs' element={<FAQ />} />
          <Route path='/pages/privacy-policy' element={<PrivacyPolicy />} />
          <Route path='/pages/special-request' element={<SpecialRequestPage />} />
          <Route path='/pages/fashionwise/app' element={<FashionWiseApp />} />
          <Route path='/pages/design/guide' element={<Blogs />} />
          <Route path='/pages/design/guide/:id' element={<BlogView />} />

        </Routes>
      </BrowserRouter>
      <FooterTest />



      <div style={{
        position: "absolute",
        right: 5,
        top: "90%",
        position: "fixed",
        zIndex: 1000,
      }}>
        <a href='https://wa.me/923264321272?text=Assalam-o-Aliakum Gee ap kia stitch krwana chaty han . Ladies Ya Gents suit !' >
          <picture >
            <source
              media="(max-width: 768px)"
              srcSet={`${whatsImgMobile} 768w`}
              sizes="768px"
              width={"100%"}
              height={"100%"}
            />
            <source
              srcSet={`${whatsImgWeb} 1280w`}
              sizes="1280px"
            />
            <img src={whatsImgMobile} style={{ width: "100%", height: '50%', borderRadius: 10 }} />
          </picture>
        </a>
      </div>


    </div >
  );
}

export default App;
