import React from 'react'
import { searchResults } from '../Configuration/ApiIntegration'
import { useState } from 'react'
import { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SingleViewComponent } from './ViewDesignScreen'

function SearchResults() {

    const [list, setList] = useState([])
    const [search, setSearch] = useState()
    const [empty, setEmpty] = useState(false)
    const [loading, setLoading] = useState(true)
    const [params, setParams] = useSearchParams()
    const query = params.get("query")
    const callToSearchApi = async () => {
        if (!search || search == "") {
            return
        }

        await searchResults(search).then((result) => {
            if (result.data.length <= 0 || result.data == [] || !result) {
                setEmpty(true)
                setLoading(false)
                setList(result.data)
            } else {
                setLoading(false)
                setList(result.data)
            }
        });
    }

    const setquery = (q) => {
        setSearch(q)
    }
    useEffect(() => {
        setquery(query)
        callToSearchApi()
    }, [search])
    return (
        <div style={{
            textAlign: "center"
        }}>
            <div className="text-center pt-4 lead bg-light fw-bold" style={{ height: 85 }}>
                <h2 className='fw-bold'> Search Results</h2>
            </div>

            <div className='row'>

                {
                    loading ?
                        <p className='mt-5 fw-medium fs-18  '>
                            Loading your query ...
                        </p>
                        :
                        empty ?
                            <p className='mt-5 fw-bold fs-18  '>
                                Not matched any data <strong className='text-danger ps-3 pe-3 '> {'\t\t'}  {search}</strong>
                            </p>
                            :
                            list?.map((item, index) => {

                                return (
                                    <div className='col-6 col-sm-6 col-md-4 col-lg-3 mt-2 '>
                                        <SingleViewComponent item={item} list={list} />


                                    </div>
                                )
                            })
                }

            </div>
        </div>
    )
}

export default SearchResults