import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { config } from '../../Configuration/Api';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';


function Trending({ type }) {

    const [list, setList] = useState([])
    const navigate = useNavigate()
    const [hover, setHover] = useState('')

    useEffect(() => {

        axios
            .get(`${config.baseurl}/stitching/sub/all/products`)
            .then(data => {
                if (type == "all") {
                    setList(data.data.Response)
                }
                else {
                    const filterList = data.data.Response?.filter((item) => item.main_category.general_category.Name == type);
                    setList(filterList)
                }
            }

            )
            .catch(error => console.log(error));

    }, [])


    return (
        <div>
            <h3 style={{
                marginTop: "30px",
                marginBottom: "20px",
                textAlign: "center"
            }}>
                <span style={{
                    color: "#222222",
                    fontSize: "2rem",
                    fontWeight: 550,
                    lineHeight: "30px",
                    textTransform: "uppercase",
                    // fontFamily: "initial",
                    // textAlign:"center",
                    borderBottom: " 1.5px solid grey"

                }}>
                    Trending
                </span>

            </h3>

            <Swiper
                spaceBetween={15}
                // centeredSlides={true}
                navigation={true}
                modules={[Navigation]}
                watchSlidesProgress={true}
                // slidesPerView={2}
                breakpoints={{
                    320: {
                        slidesPerView: 2,
                        spaceBetween: 15,

                    },
                    640: {
                        slidesPerView: 2,
                        spaceBetween: 15,

                    },
                    768: {
                        slidesPerView: 3,
                        spaceBetween: 15,

                    },

                    1024: {
                        slidesPerView: 4,
                        spaceBetween: 8,

                    },
                }
                }
                pagination={{
                    clickable: true,
                }}

            >

                {list.map((item, index) => {

                    return (

                        <SwiperSlide>
                            <div style={{
                                position: "relative",
                            }} onMouseOver={() => {
                                setHover(item.Name)
                            }} onMouseOut={() => {
                                setHover("")
                            }} onClick={() => navigate("/specific", { state: { single: item, allItems: list } })} >
                                <img
                                    src={item.Images[0]}

                                    style={{
                                        height: window.screen.width > 450 ? '450px' : '200px'
                                    }}
                                />
                                <img
                                    src={item.Images[2]}

                                    style={{
                                        height: window.screen.width > 450 ? '450px' : '200px',
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        opacity: 0,
                                        transition: `opacity 1s ease-in-out`,
                                        opacity: hover === item.Name ? 1 : 0
                                    }}
                                />
                                <p style={{
                                    textTransform: "capitalize",
                                    fontSize: 16,
                                    fontWeight: "bold",
                                    textAlign: "left",
                                    paddingLeft: 10,
                                    margin: 0
                                }}>
                                    {item.Name}
                                </p>
                                <p style={{
                                    fontSize: 16,
                                    fontWeight: 400,
                                    textAlign: "left",
                                    paddingLeft: 10,
                                    margin: 0


                                }}>
                                    Rs {item.Charge_Price}.00
                                </p>
                            </div>
                            <div>

                            </div>



                        </SwiperSlide>
                    )
                }
                )}



            </Swiper>
        </div >


    )
}





export default Trending

