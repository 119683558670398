
import React from 'react'
import Design from '../Components/Design'
import Trending from '../Components/Trending/Trending'
import MainCrousel from '../Components/Crousel/MainCrousel'
import WhatIsNew from '../Components/WhatIsNew'
import MoreToExplore from '../Components/MoreToExplore'

function Home() {

    return (
        <div style={{

        }}>

            {/*  this is for main banner slides . For example : main crousel */}

            <MainCrousel />

            {/* Whats is  new  */}
            <WhatIsNew leftSideTitle={"What's new"} type={"all"} />


            {/* Stitching Main Category Design for man , women and kids */}
            <Design />

            {/* This is for trending design */}
            <Trending type={"all"} />

            {/* user reviews and blogs as well for more explore */}
            <MoreToExplore />
         

        </div>
    )
}

export default Home