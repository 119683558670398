import React, { useState } from 'react'
// for play store images
import one1 from "../../Assests/Reviews/1.jpg"
import two2 from "../../Assests/Reviews/2.jpg"
import three3 from "../../Assests/Reviews/3.jpg"


// for fb
import fb1 from "../../Assests/Reviews/fb1.jpg"
import fb2 from "../../Assests/Reviews/fb2.jpg"
// whats app
import one from "../../Assests/Reviews/one.jpg"
import two from "../../Assests/Reviews/two.jpg"
import three from "../../Assests/Reviews/three.jpg"
import four from "../../Assests/Reviews/four.jpg"
import five from "../../Assests/Reviews/five.jpg"
import six from "../../Assests/Reviews/six.jpg"
import seven from "../../Assests/Reviews/seven.jpg"
import eight from "../../Assests/Reviews/Eight.jpg"
import nine from "../../Assests/Reviews/Nine.jpg"
import { setTitleAndDescription } from '../../SEO/SeoFile'
function CustomerReview() {
    const [viewImg, setViewImg] = useState()
    const [type, setType] = useState("All")
    const list = [
        {
            img: one,
            source: "wa"
        },
        {
            img: two,
            source: "wa"
        },
        {
            img: one1,
            source: "ps"
        },
        {
            img: three,
            source: "wa"
        },
        {
            img: fb2,
            source: "fb"
        },
        {
            img: two2,
            source: "ps"
        },
        {
            img: three3,
            source: "ps"
        },
        {
            img: four,
            source: "wa"
        },
        {
            img: five,
            source: "wa"
        },
        {
            img: fb1,
            source: "fb"
        },
        {
            img: six,
            source: "wa"
        }
        , {
            img: seven,
            source: "wa"
        },
        {
            img: eight,
            source: "wa"
        },
        {
            img: nine,
            source: "wa"
        }

    ]
    const filterList = list.filter((item) => type == "All" ? list : item.source == type)

    setTitleAndDescription("FashionWise Customer  Reviews ",
        `FashionWise believs in providing top-notch stitching service to our customers all across agaes `,
        `fashionwise reviews, online tailor reviews , customer reviews`)
    return (
        <div className='text-center mt-3'>

            <div className='mb-4'>
                <ul className=' w-60 m-auto bg-light ' >
                    <li className='d-inline fs-3 fw-medium text-capitalize p-2' onClick={() => { setType("All") }} style={{
                        backgroundColor: type == "All" ? 'black' : "white",
                        color: type == "All" ? 'white' : 'black',
                    }} >
                        All
                    </li>
                    <li className='d-inline fs-3 fw-medium text-capitalize ms-3  p-2  ' onClick={() => { setType("wa") }} style={{
                        backgroundColor: type == "wa" ? 'black' : "white",
                        color: type == "wa" ? 'white' : 'black',
                    }}>
                        WhatsApp
                    </li>
                    <br />
                    <li className='d-inline fs-3 fw-medium text-capitalize  p-2' onClick={() => { setType("fb") }} style={{
                        backgroundColor: type == "fb" ? 'black' : "white",
                        color: type == "fb" ? 'white' : 'black',
                    }}>
                        Facebook
                    </li>
                    <li className='d-inline fs-3 fw-medium text-capitalize ms-3   p-2 ' onClick={() => { setType("ps") }} style={{
                        backgroundColor: type == "ps" ? 'black' : "white",
                        color: type == "ps" ? 'white' : 'black',
                    }}>
                        Play Store
                    </li>
                </ul>
            </div>

            <div className='row container m-auto'>
                {
                    filterList.map((item) => {
                        return (
                            <div className=' col-6 col-sm-6  col-md-6  col-lg-3  border ' >
                                <button type="button" className="btn w-100" data-bs-toggle="modal"
                                    data-bs-target="#staticBackdrop" onClick={() => { setViewImg(item.img) }}>
                                    <picture >
                                        <source
                                            media="(max-width: 768px)"
                                            srcSet={`${item.img} 768w`}
                                            sizes="768px"
                                            width={"100%"}
                                            height={"100%"}

                                        />
                                        <source
                                            srcSet={`${item.img} 1280w`}
                                            sizes="1280px"
                                            width={"100%"}
                                            height={"100%"}
                                        />
                                        <img src={item.img} width={"100%"} height={"200px"} alt='Customer Reviews'/>
                                    </picture>
                                </button>
                            </div>
                        )
                    })
                }
                {/* <div className=' col-6 col-sm-6  col-md-6  col-lg-4  border ' >
                    <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => { setViewImg(one) }}>
                        <picture >
                            <source
                                media="(max-width: 768px)"
                                srcSet={`${oneC} 768w`}
                                sizes="768px"
                                width={"100%"}
                            // height={"100%"}

                            />
                            <source
                                srcSet={`${one} 1280w`}
                                sizes="1280px"
                                width={"100%"}
                                height={"80%"}
                            />
                            <img src={one} style={{ width: "100%", height: '100%' }} />
                        </picture>
                    </button>
                </div>
                <div className=' col-12 col-sm-6  col-md-6  col-lg-4  border ' >
                    <button type="button" class="btn" data-bs-toggle="modal" data-bs-target="#staticBackdrop" onClick={() => { setViewImg(one) }}>
                        <picture >
                            <source
                                media="(max-width: 768px)"
                                srcSet={`${oneC} 768w`}
                                sizes="768px"
                                width={"100vw"}
                                height={"200px"}

                            />
                            <source
                                srcSet={`${one} 1280w`}
                                sizes="1280px"
                                width={"100%"}
                                height={"80%"}
                            />
                            <img src={one} width={"100%"} height={"100px"} />
                        </picture>
                    </button>
                </div>*/}
            </div>




            <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h1 class="modal-title fs-5" id="staticBackdropLabel">View Image</h1>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">
                            <img src={`${viewImg}`} width={"100%"} />
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            {/* <button type="button" class="btn btn-primary">Understood</button> */}
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}

export default CustomerReview