import React from "react";
import { useState } from "react";
import { OrderStatus } from "../Configuration/ApiIntegration";

function OrderTracking() {
    const [loading, setLoading] = useState(false)
    const [presentStatus, setPresentStatus] = useState(false)
    const [number, setNumber] = useState()
    const [input, setInput] = useState()
    const [msg, setMsg] = useState()
    const [found, setFound] = useState(false)
    const showStatus = async (number) => {
        setLoading(true)
        console.log(number);
        const order = await OrderStatus(number);
        if (order.success == true) {
            setFound(true)
            setLoading(false)
            setMsg(order.message)
            console.log(order.message);
            setPresentStatus(order.Response.status)
            setNumber(order.Response.number)
        }
        else {
            setFound(false)
            setLoading(false)
            setMsg(order.message)
            // setPresentStatus(order.Response.status)
            setNumber(order.Response.number)
        }
    }
    return (
        <div>
            <div className="text-center p-4 lead bg-light mb-4" style={{}}>
                <h2> TRACK YOUR ORDER</h2>
            </div>
            <form>
                <div className="container text-center lead mb-5">
                    <label for="inputText" class="form-label">
                        Enter Order Number:
                    </label>
                    <input type="text" class="form-control" id="inputText" style={{ width: "40%", margin: "auto" }}
                        onChange={(event) => setInput(event.target.value)}
                    />
                    {/* <div>
                        <label for="inputEmail4" class="form-label">
                            Enter Email Address:
                        </label>
                        <input type="email" class="form-control" id="inputEmail4" style={{ width: "40%", margin: "auto" }} />
                    </div> */}
                    <div className="mt-3">
                        <button type="button" class="btn btn-primary" data-bs-toggle="modal"
                            style={{ width: "40%", margin: "auto", fontWeight: 'bold' }}
                            data-bs-target="#staticBackdro" onClick={() => {
                                showStatus(input)
                                console.log(input);
                            }}>
                            Order Track
                        </button>
                        {/* <button type="button"
                            class="btn border border-dark"
                            style={{ width: "40%", margin: "auto", fontWeight: 'bold' }}>
                            Order Track</button> */}
                    </div>
                </div>
            </form>
            <div class="modal fade" id="staticBackdro" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="staticBackdropLabel">Order Status</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div class="modal-body">

                            {
                                loading ?
                                    <span>
                                        Loading...
                                    </span>
                                    :
                                    found ?
                                        <span>
                                            {msg}  <b>{presentStatus}</b>
                                            <br />
                                            OrderNumber : <b>{input}</b>
                                            <br />
                                            <a href='/pages/contact'>
                                                Read more
                                            </a>
                                        </span>
                                        :
                                        <span>
                                            <b>{msg}</b>
                                            <br />
                                            OrderNumber : <b style={{color:'tomato'}}>{input}</b>
                                            {/* Your order  <b>{number}</b> current status is <b>{presentStatus}</b> */}
                                            <br />
                                            <a href='/pages/contact'>
                                                Read more
                                            </a>
                                        </span>
                            }
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                            {/* <button type="button" class="btn btn-primary" onClick={() => {
                                                                    // <Link to={"/pages/contact"} />
                                                                }}></button> */}
                            <a href='/pages/contact' style={{
                                backgroundColor: "royalblue",
                                padding: 5,
                                color: "white",
                                fontWeight: "bold",
                                textDecorationLine: "none"
                            }}>
                                Contact US
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default OrderTracking;