import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import MobileSpecialRequest from '../Assests/Images/MobileSpecialRequest.jpg'
import DesktopSpecialRequest from '../Assests/Images/DesktopSpecialRequest.jpg'

import DesktopFashionWiseApp from '../Assests/Images/DesktopFashionWiseApp.jpg'
import MobileFashionWiseApp from '../Assests/Images/MobileFashionWiseApp.jpg'

import MobileCustomerReviews from '../Assests/Images/MobileCustomerReviews.jpg'

import { Navigation, Pagination, EffectCoverflow } from 'swiper/modules';
import { useNavigate } from 'react-router-dom';
function MoreToExplore() {
    const navigate = useNavigate()
    return (
        <div>
            <h3 style={{
                marginTop: "30px",
                marginBottom: "20px",
                textAlign: "center"
            }}>
                <span style={{
                    color: "#222222",
                    fontSize: "2rem",
                    fontWeight: 550,
                    lineHeight: "30px",
                    textTransform: "uppercase",
                    borderBottom: " 1.5px solid grey"

                }}>
                    More to explore
                </span>

            </h3>
            <Swiper
                effect={'coverflow'}
                grabCursor={true}
                centeredSlides={true}
                navigation={true}
                slidesPerView={1.5}
                coverflowEffect={{
                    rotate: 50,
                    stretch: 0,
                    depth: 100,
                    modifier: 1,
                    slideShadows: true,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination, Navigation]}
                style={{
                    width: window.screen.width > 450 ? '80%' : "100%",
                    height: window.screen.width > 450 ? '450px' : '250px'
                }}
            >
                <SwiperSlide>
                    <picture onClick={() => navigate("/pages/customer/reviews")} >
                        <source
                            media="(max-width: 768px)"
                            srcSet={`${MobileCustomerReviews} 768w`}
                            sizes="768px"
                            width={"100%"}
                            height={"100%"}
                        />
                        <source
                            srcSet={`${MobileCustomerReviews} 1280w`}
                            sizes="1280px"
                            width={"100%"}
                            height={"100%"}

                            style={{
                                borderRadius: 200
                            }}

                        />
                        <img src={MobileCustomerReviews} height={"100%"} width={"100%"}
                        />
                    </picture>
                </SwiperSlide>
                <SwiperSlide>
                    <picture onClick={() => {
                        navigate("pages/special-request")
                    }}
                        className=''  >
                        <source
                            media="(max-width: 768px)"
                            srcSet={`${MobileFashionWiseApp} 768w`}
                            sizes="768px"
                            width={"100%"}
                            height={"100%"}
                        />
                        <source
                            srcSet={`${DesktopFashionWiseApp} 1280w`}
                            sizes="1280px"
                            width={"100%"}
                            height={"100%"}

                            style={{
                                borderRadius: 200
                            }}

                        />
                        <img src={MobileFashionWiseApp} height={"100%"} width={"100%"}
                        />
                    </picture>
                </SwiperSlide>
                <SwiperSlide>
                    <picture onClick={() => {
                        navigate("pages/special-request")
                    }}
                        className=''  >
                        <source
                            media="(max-width: 768px)"
                            srcSet={`${MobileSpecialRequest} 768w`}
                            sizes="768px"
                            width={"100%"}
                            height={"100%"}
                        />
                        <source
                            srcSet={`${DesktopSpecialRequest} 1280w`}
                            sizes="1280px"
                            width={"100%"}
                            height={"100%"}

                            style={{
                                borderRadius: 200
                            }}

                        />
                        <img src={MobileSpecialRequest} height={"100%"} width={"100%"}
                        />
                    </picture>
                </SwiperSlide>


            </Swiper>
        </div>
    )
}

export default MoreToExplore