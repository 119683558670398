
// this is a specific stitching design screen

import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Trending from '../Components/Trending/Trending';
import { config } from '../Configuration/Api';
// import ReactImageZoom from 'react-image-zoom';
// import Zoom from 'react-img-zoom'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function SpecificProductScreen() {
    
    const location = useLocation();
    const navigate = useNavigate()
    const singleProduct = location.state.single
    const AllProduct = location.state.allItems
    const actual = singleProduct.Actual_Price
    const charge = singleProduct.Charge_Price
    const discount = parseInt((actual - charge) * 100 / actual)
    const [slec, setSlec] = useState(0)
    const [view, setView] = useState("details");
    const [size, setSize] = useState("small")
    const [modal, setModal] = useState(false)
    const [choose, setChoose] = useState(false)
    const data = singleProduct.Size;
    // console.log(data);
    const [state, setState] = useState(data.map((item, index) => {
        var newState = `s${index}`
        return newState = item.name
    }));

    function onChange(name, val) {
        setState(prevState => ({ ...prevState, [name]: val }));
    }

    const buyNow = () => {
        console.log("dasdsad");
        if (!choose) {
            alert("Please choose the size first")
            return
        }

        navigate(`/order/${singleProduct._id}`, { state: { size: size, product: singleProduct, all: AllProduct } })

        // setModal(true)
    }
    const height = window.innerHeight / 2
    const props = { height: 250, img: singleProduct.Images[slec] }
    useEffect(()=> {
        window.scrollTo(0, 0);
    },[])
    return (
        <div className='bg-light'>
            {/* this is for screen breadcrum for easily change the screen */}

            <div>
                <nav aria-label="breadcrumb" className='' style={{
                    width: '90%',
                    //height: 60,
                    backgroundColor: "#eee",
                    margin: "auto"
                }}>
                    <ol className="breadcrumb" style={{
                        paddingTop: 20,
                        paddingBottom: 10,
                        margin: "auto",
                        paddingLeft: 10
                    }}>
                        <li className="breadcrumb-item pl-5"><a href="/">Home</a></li>
                        <li className="breadcrumb-item"><a href={`/stitching-design/${singleProduct.main_category.general_category.Name}`}> All {singleProduct.main_category.general_category.Name}'s  Design </a></li>
                        <li className="breadcrumb-item active" aria-current="page">
                            {singleProduct.Name}
                        </li>
                    </ol>
                </nav>
            </div>

            <div className='row mt-3' style={{
                borderWidth: 2,
                borderColor: "black",
                width: '90%',
                margin: "auto",
            }}>

                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-2' style={{
                    borderWidth: 2, borderColor: "black"
                }}>
                    {/* <img src={singleProduct.Images[slec]} height={window.innerHeight / 2} width={'100%'} style={{
                        borderRadius: 10
                    }} /> */}
                    {/* <ReactImageZoom width="500" height={250} zoomWidth={400} img={singleProduct.Images[slec]} /> */}

                    {/* <Zoom
                        img={singleProduct.Images[slec]}
                        zoomScale={3}
                        height={500}
                        width={500}
                    /> */}

                    <Zoom>
                        <div style={{
                            position: "relative",
                        }}>
                            <img
                                alt="FashoionWise Product images are in progress"
                                src={singleProduct.Images[slec]}
                                width="100%"
                                height={window.innerHeight / 2}
                                style={{
                                    borderRadius: 20,
                                    borderColor: "black",
                                    borderWidth: 2,
                                    // position: "relative",

                                }}
                            />
                            {/* <i class="bi bi-arrows-fullscreen" style={{
                                position: "absolute",
                                bottom: 20,
                                right: 20,
                                fontSize: "1rem",
                                // backgroundColor: "grey",
                                color: "black",
                                padding: 5,
                                borderRadius: 10
                            }}></i> */}
                        </div>
                    </Zoom>
                    {
                        singleProduct.Images.map((img, index) => {
                            return (

                                <div className='' style={{
                                    display: "inline"
                                }} >
                                    {
                                        slec == index ?
                                            <img src={img} height={"80"} width={"80"} style={{
                                                borderRadius: 20,
                                                marginLeft: '1%',
                                                marginTop: "1%",
                                                borderWidth: 2,
                                                borderColor: slec == index ? "black" : "red"
                                            }} onClick={() => {
                                                console.log(index);
                                                setSlec(index)
                                            }}
                                                className="col-sm-2 col-md-2 col-lg-3 col-xl-3  mt-2 border border-2 border-dark"
                                            />
                                            :
                                            <img src={img} height={"80"} width={"80"} style={{
                                                borderRadius: 20,
                                                marginLeft: '1%',
                                                marginTop: "1%",
                                                // borderWidth: 2,
                                                // borderColor: slec == index ? "black" : "red"
                                            }} onClick={() => {
                                                console.log(index);
                                                setSlec(index)
                                            }}
                                                className="col-sm-2 col-md-2 col-lg-3 col-xl-3  mt-2 "
                                            />

                                    }

                                </div>
                            )

                        })
                    }

                </div>

                <div className='col-sm-12 col-md-6 col-lg-6 col-xl-6  ' >

                    {/* <div className='row' >
                        <div className='col-sm-12 col-md-4 col-lg-4 col-xl-4 '>
                            
                            {
                                singleProduct.Images.map((img, index) => {
                                    return (

                                        <img src={img} height={"100"} width={"100"} style={{
                                            borderRadius: 20,
                                            marginLeft: '1%',
                                            marginTop: "1%",
                                            borderWidth: 2,
                                            borderColor: slec == index ? "black" : "red"
                                        }} onClick={() => {
                                            console.log(index);
                                            setSlec(index)
                                        }}
                                            className="col-sm-3 col-md-8 col-lg-8 col-xl-8  mt-2"
                                        />

                                    )

                                })
                            }
                        </div> */}

                    {/* <div className='col-sm-12 col-md-8 col-lg-8 col-xl-8 bg-light '> */}

                    <div className='mt-3 ' >
                        <div className='row row-cols-auto'>
                            <span style={{
                                textTransform: "capitalize",
                                color: "#222222",
                                fontSize: "25px",
                                fontWeight: '700',
                                display: "flex",

                            }} className="col">
                                {singleProduct.Name}
                            </span>
                            <span style={{
                                textTransform: "capitalize",
                                color: "grey",
                                fontWeight: "600",
                                marginTop: 10,
                                // lineHeight: "2",
                            }} className='col'>
                                (Design)
                            </span>
                        </div>

                        <div className='row row-cols-auto'>
                            <span style={{
                                textTransform: "capitalize",
                                color: "darkgray",
                                fontWeight: '700',
                                display: "flex",

                            }} className="col">
                                SKU# :
                            </span>
                            <span style={{
                                textTransform: "capitalize",
                                color: "black",
                                fontWeight: "bold"
                            }} className='col'>
                                {singleProduct.Design_SKU}
                            </span>
                        </div>
                        <span style={{
                            textTransform: "uppercase",
                            color: "darkgrey",
                            fontWeight: '700',
                            display: "flex"
                        }}>
                            In Stock
                        </span>

                        <span style={{
                            textTransform: "uppercase",
                            color: "black",
                            fontSize: 14,
                            display: "flex",
                            textDecorationLine: "underline"
                        }}>
                            Now ready to get designer dress
                        </span>



                        <div className=' mt-2 row-col-auto' style={{ display: "flex" }}>
                            <span style={{
                                textTransform: "capitalize",
                                color: "#222222",
                                fontSize: `3vh`,
                                fontWeight: 'bold',
                                display: 'flex'
                                // lineHeight: "22",


                            }} >
                                PKR {singleProduct.Charge_Price}.00
                            </span>
                            <span style={{
                                textTransform: "capitalize",
                                color: "darkgrey",
                                fontSize: "3vh",
                                fontWeight: '700',
                                display: 'flex',
                                // lineHeight: "",
                                marginLeft: 15,
                                textDecorationLine: "line-through"

                            }} >
                                PKR{singleProduct.Actual_Price}.00
                            </span>

                        </div>
                        <div style={{
                            display: "flex",
                            marginTop: 5
                        }}>
                            <span style={{
                                textTransform: "capitalize",
                                color: "white",
                                fontSize: "18",
                                fontWeight: 'bold',
                                lineHeight: "30px",
                                backgroundColor: "grey",
                                borderRadius: 5,
                                paddingRight: 15,
                                paddingLeft: 15
                            }}>
                                {discount}% off
                            </span>
                        </div>



                        {/* This is for buy now button */}
                        <button style={{
                            // backgroundColor: 'black',
                            color: "black",
                            fontSize: 20,
                            fontWeight: '700',
                            width: "98%",
                            marginTop: "5%",
                            marginBottom: "5%"
                        }} onClick={buyNow} type="button" className='border border-3 border-dark'  >
                            Buy Now
                        </button>


                        <div class=" m-4 text-danger border-bottom border-secondary"></div>

                        {/* <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                                    Launch static backdrop modal
                                </button> */}


                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                            <div class="modal-dialog">
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5 class="modal-title" id="staticBackdropLabel">Modal title</h5>
                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                    </div>
                                    <div class="modal-body">
                                        ...
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                                        <button type="button" class="btn btn-primary">Understood</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mt-3 mb-3'>

                            <span className='shadow-lg  ml-2' style={{
                                backgroundColor: view == 'details' ? '#000' : "#eee",
                                color: view == 'details' ? 'white' : "black",
                                width: 100,
                                height: 100,
                                borderRadius: 50,
                                fontSize: 16,
                                fontWeight: "bold",
                                paddingTop: 35,

                            }} onClick={() => {
                                setView("details")
                            }}>
                                Details
                            </span>
                            <span className='shadow-lg' style={{
                                backgroundColor: view == 'mesurements' ? '#000' : "#eee",
                                color: view == 'mesurements' ? 'white' : "black",
                                width: 100,
                                height: 100,
                                borderRadius: 50,
                                fontSize: 16,
                                fontWeight: "bold",
                                paddingTop: 35,
                                marginLeft: 5
                            }} onClick={() => {
                                setView("mesurements")
                            }}>
                                Sizes
                            </span>


                        </div>


                        <div className='mt-3'>
                            {
                                view == "details" ?
                                    <div>
                                        <span className='m-5 fw-bold'>
                                            {singleProduct.Description}
                                        </span>
                                        <div class=" m-4 text-danger border-bottom border-secondary"></div>

                                        <div className='row ' >
                                            <div className='col '>
                                                <p className='mt-4 ' >
                                                    <strong style={{ fontSize: 24, display: "flex", textDecorationLine: "underline" }}>
                                                        Product Details <br />
                                                    </strong>
                                                    <span style={{ display: 'flex', fontSize: 18, lineHeight: 2 }}>
                                                        Stitching Service Design
                                                    </span>
                                                </p>
                                            </div>
                                        </div>

                                        <div style={{
                                            width: '80%',
                                            margin: "auto",
                                        }} className="row mt-3">
                                            <div className='col-6'>
                                                <strong style={{ fontSize: 18, display: 'flex', }}>
                                                    Fabric
                                                </strong>
                                            </div>
                                            <div className='col-6'>
                                                <span style={{ fontSize: 18, display: 'flex', }}>
                                                    {singleProduct.FabricName}
                                                </span>
                                            </div>

                                        </div>

                                        {/* <div style={{
                                            width: '80%',
                                            margin: "auto",
                                        }} className="row mt-3">
                                            <div className='col-6'>
                                                <strong style={{ fontSize: 18, display: 'flex', }}>
                                                    Colour
                                                </strong>
                                            </div>
                                            <div className='col-6'>
                                                <span style={{ fontSize: 18, display: 'flex', }}>
                                                    light red
                                                </span>
                                            </div>

                                        </div> */}

                                        <div style={{
                                            width: '80%',
                                            margin: "auto",
                                        }} className="row mt-3">
                                            <div className='col-6'>
                                                <strong style={{ fontSize: 18, display: 'flex', }}>
                                                    Category
                                                </strong>
                                            </div>
                                            <div className='col-6'>
                                                <span style={{ fontSize: 18, display: 'flex', }}>
                                                    {singleProduct.main_category.general_category.Name} Stitching Design
                                                </span>
                                            </div>

                                        </div>


                                        <div style={{
                                            width: '80%',
                                            margin: "auto",
                                        }} className="row mt-3">
                                            <div className='col-6'>
                                                <strong style={{ fontSize: 18, display: 'flex', }}>
                                                    Collection
                                                </strong>
                                            </div>
                                            <div className='col-6'>
                                                <span style={{ fontSize: 18, display: 'flex', }}>
                                                    {singleProduct.main_category.Name}
                                                </span>
                                            </div>

                                        </div>
                                        <div style={{
                                            width: '80%',
                                            margin: "auto",
                                        }} className="row mt-3">
                                            <div className='col-9'>
                                                <strong style={{ fontSize: 18, display: 'flex', }}>
                                                    FabricRequired
                                                </strong>
                                            </div>
                                            <div className='col-3 fw-bold'>
                                                <span style={{ fontSize: 18, display: 'flex', }}>
                                                    {singleProduct.FabricRequired}m
                                                </span>
                                            </div>

                                        </div>

                                        {/* <div style={{
                                            width: '80%',
                                            margin: "auto",
                                        }} className="row mt-3">
                                            <div className='col-6'>
                                                <strong style={{ fontSize: 18, display: 'flex', }}>
                                                    Disclaimer
                                                </strong>
                                            </div>
                                            <div className='col-6'>
                                                <span style={{ fontSize: 18, display: 'flex', }}>
                                                   {singleProduct.Disclaimer}
                                                </span>
                                            </div>

                                        </div> */}
                                        <div class=" m-4 text-danger border-bottom border-secondary"></div>

                                        <p className='mt-5' style={{}}>
                                            <strong style={{ fontSize: 20, lineHeight: 2, display: "flex", textDecorationLine: "underline" }}>
                                                Disclaimer: <br />
                                            </strong>
                                            <span style={{ display: "flex" }}>
                                                {singleProduct.Disclaimer}
                                            </span>
                                        </p>



                                    </div>
                                    :
                                    <div>
                                        {/* this is for size boxes */}
                                        <div className='row mt-5'>

                                            <span className='shadow-lg  ml-2' style={{
                                                backgroundColor: size == 'small' ? '#000' : "#ddd",
                                                color: size == 'small' ? 'white' : "black",
                                                width: 80,
                                                height: 80,
                                                borderRadius: 40,
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                paddingTop: 25,

                                            }} onClick={() => {
                                                setSize("small")
                                                setChoose(true)
                                            }}>
                                                Small
                                            </span>
                                            <span className='shadow-lg  ml-2' style={{
                                                backgroundColor: size == 'medium' ? '#000' : "#ddd",
                                                color: size == 'medium' ? 'white' : "black",
                                                width: 80,
                                                height: 80,
                                                borderRadius: 40,
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                paddingTop: 25,
                                                marginLeft: 10

                                            }} onClick={() => {
                                                setSize('medium')
                                                setChoose(true)
                                            }}>
                                                Medium
                                            </span>

                                            <span className='shadow-lg  ml-2' style={{
                                                backgroundColor: size == 'large' ? '#000' : "#ddd",
                                                color: size == 'large' ? 'white' : "black",
                                                width: 80,
                                                height: 80,
                                                borderRadius: 40,
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                paddingTop: 25,
                                                marginLeft: 10

                                            }} onClick={() => {
                                                setSize("large")
                                                setChoose(true)
                                            }}>
                                                Large
                                            </span>

                                            <span className='shadow-lg  ml-2 mt-2' style={{
                                                backgroundColor: size == 'own' ? '#000' : "#ddd",
                                                color: size == 'own' ? 'white' : "black",
                                                width: 80,
                                                height: 80,
                                                borderRadius: 40,
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                paddingTop: 25,
                                                marginLeft: 10

                                            }} onClick={() => {
                                                setSize("own")
                                                setChoose(true)
                                            }}>
                                                Own Fit
                                            </span>

                                            <span className='shadow-lg  ml-2 mt-2' style={{
                                                backgroundColor: size == 'custome' ? '#000' : "#ddd",
                                                color: size == 'custome' ? 'white' : "black",
                                                width: 85,
                                                height: 80,
                                                borderRadius: 40,
                                                fontSize: 16,
                                                fontWeight: "bold",
                                                paddingTop: 25,
                                                marginLeft: 10

                                            }} onClick={() => {
                                                setSize("custome")
                                                setChoose(true)
                                            }}>
                                                Custome
                                            </span>
                                        </div>

                                        {
                                            size == 'own' ?
                                                // own size styling
                                                <div className='mt-5 border'>
                                                    <span style={{
                                                        fontSize: 16,
                                                        fontWeight: "bold",
                                                        padding: 5,
                                                        margin: 5

                                                    }}>
                                                        You have to provide the your own clothes that are good fit for you
                                                        so we will ready your clothes according to them . Feel free to
                                                        contact Us

                                                    </span>
                                                </div>
                                                :
                                                size == "small" ?
                                                    //small size
                                                    <div className='mt-5'>
                                                        <div className='row bg-dark '>
                                                            <span className='col-4' style={{ color: "white", padding: 10, fontWeight: "bold" }}>
                                                                Measurement
                                                            </span>

                                                            <span className='col-4' style={{ color: "white", padding: 10, fontWeight: "bold" }}>
                                                                Name
                                                            </span>
                                                            <span className='col-4' style={{ color: "white", padding: 10, fontWeight: "bold" }}>
                                                                Inches
                                                            </span>
                                                        </div>
                                                        {
                                                            singleProduct.Size.map((s, index) => {
                                                                const name = s.name.split('_').join(" ")
                                                                console.log(s)
                                                                return (
                                                                    <div className='row mt-2'>
                                                                        <div className='col-4'>
                                                                            {/* <img src={`${config.baseurl}${s.image}`} height={"100"} width={"100"} style={{
                                                                                borderRadius: 20,
                                                                                marginLeft: '1%',
                                                                                marginTop: "1%",
                                                                            }} onClick={() => {
                                                                                console.log(index);
                                                                                // setSlec(index)
                                                                            }}
                                                                                className="col-sm-3 col-md-8 col-lg-8 col-xl-8 bg-light  border border-2 border-dark"
                                                                            /> */}
                                                                            <Zoom>
                                                                                <img
                                                                                    alt="FashoionWise Product images are in progress"
                                                                                    src={`${config.baseurl}${s.image}`}
                                                                                    height={"100"} width={"100"}
                                                                                    style={{
                                                                                        borderRadius: 20,
                                                                                        marginLeft: '1%',
                                                                                        marginTop: "1%",
                                                                                    }}
                                                                                    className="col-sm-3 col-md-8 col-lg-8 col-xl-8 bg-light  " />
                                                                                {/* <i class="bi bi-zoom-in" style={{
                                                                                    position: "absolute",
                                                                                    bottom: 0,
                                                                                    right: 0,
                                                                                    fontSize: 16,
                                                                                    // backgroundColor: "grey",
                                                                                    color: "black",
                                                                                    paddingBottom: 5,
                                                                                    borderRadius: 10
                                                                                }}></i> */}
                                                                            </Zoom>
                                                                        </div>
                                                                        <div className='col-4 mt-4'>
                                                                            <span style={{

                                                                                textTransform: "capitalize"
                                                                            }}>
                                                                                {
                                                                                    name
                                                                                }
                                                                            </span>
                                                                        </div>
                                                                        <div className='col-4 mt-4'>
                                                                            <span>
                                                                                {
                                                                                    s.small
                                                                                }"
                                                                            </span>
                                                                        </div>
                                                                    </div>

                                                                )
                                                            })
                                                        }
                                                    </div>
                                                    :
                                                    size == 'medium' ?
                                                        <div className='mt-5'>
                                                            <div className='row bg-dark '>
                                                                <span className='col-4' style={{ color: "white", padding: 10, fontWeight: "bold" }}>
                                                                    Measurement
                                                                </span>

                                                                <span className='col-4' style={{ color: "white", padding: 10, fontWeight: "bold" }}>
                                                                    Name
                                                                </span>
                                                                <span className='col-4' style={{ color: "white", padding: 10, fontWeight: "bold" }}>
                                                                    Inches
                                                                </span>
                                                            </div>
                                                            {
                                                                singleProduct.Size.map((s, index) => {
                                                                    const name = s.name.split('_').join(" ")
                                                                    console.log(s)
                                                                    return (
                                                                        <div className='row mt-2'>
                                                                            <div className='col-4'>
                                                                                {/* <img src={`${config.baseurl}${s.image}`} height={"100"} width={"100"} style={{
                                                                                    borderRadius: 20,
                                                                                    marginLeft: '1%',
                                                                                    marginTop: "1%",
                                                                                }} onClick={() => {
                                                                                    console.log(index);
                                                                                    // setSlec(index)
                                                                                }}
                                                                                    className="col-sm-3 col-md-8 col-lg-8 col-xl-8 bg-light  border border-2 border-dark"
                                                                                /> */}
                                                                                <Zoom>
                                                                                    <img
                                                                                        alt="FashoionWise Product images are in progress"
                                                                                        src={`${config.baseurl}${s.image}`}
                                                                                        height={"100"} width={"100"}
                                                                                        style={{
                                                                                            borderRadius: 20,
                                                                                            marginLeft: '1%',
                                                                                            marginTop: "1%",
                                                                                        }}
                                                                                        className="col-sm-3 col-md-8 col-lg-8 col-xl-8 bg-light  " />
                                                                                    {/* <i class="bi bi-zoom-in" style={{
                                                                                        position: "absolute",
                                                                                        bottom: 0,
                                                                                        right: 0,
                                                                                        fontSize: 16,
                                                                                        // backgroundColor: "grey",
                                                                                        color: "grey",
                                                                                        padding: 5,
                                                                                        borderRadius: 10
                                                                                    }}></i> */}
                                                                                </Zoom>
                                                                            </div>
                                                                            <div className='col-4 mt-4'>
                                                                                <span style={{

                                                                                    textTransform: "capitalize"
                                                                                }}>
                                                                                    {
                                                                                        name
                                                                                    }
                                                                                </span>
                                                                            </div>
                                                                            <div className='col-4 mt-4'>
                                                                                <span>
                                                                                    {
                                                                                        s.medium
                                                                                    }"
                                                                                </span>
                                                                            </div>
                                                                        </div>

                                                                    )
                                                                })
                                                            }
                                                        </div>
                                                        :
                                                        size == 'large' ?

                                                            <div className='mt-5'>
                                                                <div className='row bg-dark '>
                                                                    <span className='col-4' style={{ color: "white", padding: 10, fontWeight: "bold" }}>
                                                                        Measurement
                                                                    </span>

                                                                    <span className='col-4' style={{ color: "white", padding: 10, fontWeight: "bold" }}>
                                                                        Name
                                                                    </span>
                                                                    <span className='col-4' style={{ color: "white", padding: 10, fontWeight: "bold" }}>
                                                                        Inches
                                                                    </span>
                                                                </div>
                                                                {
                                                                    singleProduct.Size.map((s, index) => {
                                                                        const name = s.name.split('_').join(" ")
                                                                        console.log(s)
                                                                        return (
                                                                            <div className='row mt-2'>
                                                                                <div className='col-4'>
                                                                                    {/* <img src={`${config.baseurl}${s.image}`} height={"100"} width={"100"} style={{
                                                                                        borderRadius: 20,
                                                                                        marginLeft: '1%',
                                                                                        marginTop: "1%",
                                                                                    }} onClick={() => {
                                                                                        console.log(index);
                                                                                        // setSlec(index)
                                                                                    }}
                                                                                        className="col-sm-3 col-md-8 col-lg-8 col-xl-8 bg-light  border border-2 border-dark"
                                                                                    /> */}

                                                                                    <Zoom>
                                                                                        <img
                                                                                            alt="FashoionWise Product images are in progress"
                                                                                            src={`${config.baseurl}${s.image}`}
                                                                                            height={"100"} width={"100"}
                                                                                            style={{
                                                                                                borderRadius: 20,
                                                                                                marginLeft: '1%',
                                                                                                marginTop: "1%",
                                                                                            }}
                                                                                            className="col-sm-3 col-md-8 col-lg-8 col-xl-8 bg-light  " />
                                                                                        {/* <i class="bi bi-zoom-in" style={{
                                                                                            position: "absolute",
                                                                                            bottom: 0,
                                                                                            right: 0,
                                                                                            fontSize: 16,
                                                                                            // backgroundColor: "grey",
                                                                                            color: "black",
                                                                                            padding: 5,
                                                                                            borderRadius: 10
                                                                                        }}></i> */}
                                                                                    </Zoom>
                                                                                </div>
                                                                                <div className='col-4 mt-4'>
                                                                                    <span style={{

                                                                                        textTransform: "capitalize"
                                                                                    }}>
                                                                                        {
                                                                                            name
                                                                                        }
                                                                                    </span>
                                                                                </div>
                                                                                <div className='col-4 mt-4'>
                                                                                    <span>
                                                                                        {
                                                                                            s.large
                                                                                        }"
                                                                                    </span>
                                                                                </div>
                                                                            </div>

                                                                        )
                                                                    })
                                                                }
                                                            </div>
                                                            :
                                                            size == "custome" ?

                                                                <div className='mt-5 border'>
                                                                    <span style={{
                                                                        fontSize: 16,
                                                                        fontWeight: "bold",
                                                                        padding: 5,
                                                                        margin: "auto",
                                                                        textAlign: "center"

                                                                    }}>
                                                                        We only accept custom order from app but we will take your measurement at the confirmation time.Any
                                                                        Question feel free to contact

                                                                    </span>
                                                                </div>
                                                               

                                                                : ''



                                        }
                                        <div class=" m-4 text-danger border-bottom border-secondary"></div>

                                        <p className='mt-5'>
                                            <strong style={{ fontSize: 18, display: "flex", lineHeight: 2, textDecorationLine: "underline" }}>
                                                Recommend: <br />
                                            </strong>
                                            <span style={{ display: "flex" }}>
                                                We recommand the standard Sizes . Please choose carefully. Since all measurements in
                                                Inches
                                            </span>
                                        </p>
                                    </div>
                            }

                        </div>
                    </div>
                    {/* </div> */}
                </div>

                {/* </div> */}

            </div>

            {/* This is for trending products */}



            <Trending type={"all"} />


        </div >
    )
}

export default SpecificProductScreen