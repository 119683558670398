import React, { useState } from 'react'
import { contactMsg } from '../Configuration/ApiIntegration'
import { setTitleAndDescription } from '../SEO/SeoFile'

function Contact() {
    const [name, setName] = useState()
    const [phone, setPhone] = useState()
    const [email, setEmail] = useState()
    const [message, setMessage] = useState()
    const [response, setReponse] = useState()
    const [loading, setLoading] = useState(false)
    const [show, setShow] = useState(false)
    setTitleAndDescription("FashionWise Contact Number - Contact Us - Shop Address",
        `FashionWise provides online and in-store stitching services . You can visit our booking offices 
    and order online.For FashionWise inquiries Contact us at wecare@thefashionwise.com or call +92 326 432 1272 for 
    support with online shopping, queries and Product returns. `
        , `FashionWise Contact , FashionWise Contact Number , fashionwise number , 
    best tailor in lahore phone number , tailor in lahore phone number , men tailor in lahore phone number ,
    online tailor phone number , Online tailor in lahore`)
    return (
        <div style={{
            // textAlign:"center"
        }}>
            {/* ======= Contact Section ======= */}
            <section id="contact" className="contact bg-light">
                <div className="container" data-aos="fade-up">
                    <div className="section-title mb-3 ">
                        <h2 style={{ textAlign: "center" }}>Contact</h2>
                    </div>
                    <div className="row">
                        <div className="col-lg-5 d-flex align-items-stretch">
                            <div className="info">
                                <div className="address">
                                    <i class="bi bi-geo-alt" style={{ color: "green", fontWeight: "bold" }}></i>
                                    <h4>Location:</h4>
                                    <p>
                                        Plot#70D , Khyber Block <br />
                                        Allama iqbal town , Lhr 54000<br />
                                        Pakistan
                                    </p>
                                </div>
                                <div className="email">
                                    <i className="bi bi-envelope" ></i>
                                    <h4>Email:</h4>
                                    <p>wecare@thefashionwise.com</p>
                                </div>
                                <div className="phone">
                                    <i class="bi bi-phone-vibrate"></i>
                                    <h4>Call:</h4>
                                    <p>+92 326 432 1272</p>
                                </div>
                                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.0406979117806!2d74.29437527539633!3d31.52304214701045!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x391903ef7ec48237%3A0x848ee0d39bb969ed!2sFashionWise!5e0!3m2!1sen!2sus!4v1695141922983!5m2!1sen!2sus"
                                    allowfullscreen="" loading="lazy"
                                    style={{ border: 0, width: '100%', height: '290px' }} allowFullScreen
                                    referrerpolicy="no-referrer-when-downgrade" />

                            </div>
                        </div>
                        <div className="col-lg-7 mt-5 mt-lg-0 d-flex align-items-stretch">
                            {/* action="forms/contact.php" className="php-email-form" method="post" role="form" */}
                            <form onSubmit={async (e) => {
                                e.preventDefault();
                                if (e) {
                                    console.log("true");
                                    setShow(true)
                                    console.log(name, email);
                                    const response = await contactMsg(name, email, phone, message);
                                    console.log(response);
                                    if (response.success == true) {

                                        setReponse(response.message)
                                        alert("Thanks for contact us!")
                                    }
                                    else {
                                        alert("Sorry we cannot send your message!")
                                    }
                                } else {
                                    console.log("false");
                                    alert('Something went wrong!')
                                }

                            }}>
                                <div className="row">
                                    <div className="form-group col-md-6">
                                        <label htmlFor="name">Your Name</label>
                                        <input type="text" name="name" className="form-control" id="name" required
                                            onChange={(event) => setName(event.target.value)} />
                                    </div>
                                    <div className="form-group col-md-6">
                                        <label htmlFor="name">Your Email</label>
                                        <input type="email" className="form-control" name="email" id="email" required
                                            onChange={(event) => setEmail(event.target.value)} />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Phone</label>
                                    <input type="text" className="form-control" name="subject" id="subject" required
                                        onChange={(event) => setPhone(event.target.value)} />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="name">Message</label>
                                    <textarea className="form-control" name="message" rows={10} required defaultValue={""}
                                        onChange={(event) => setMessage(event.target.value)} />
                                </div>
                                {/* <div className="my-3">
                                    <div className="">Your message has been sent. Thank you!</div>
                                </div> */}
                                <div className="text-center mt-2">
                                    <button type='submit' className='btn btn-secondary'
                                        data-bs-toggle="modal"
                                        data-bs-target="#staticBackdrop" >
                                        Send Message
                                    </button>

                                </div>
                                {
                                    show ?


                                        <div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                            <div class="modal-dialog">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <h5 class="modal-title" id="staticBackdropLabel">Contact US</h5>
                                                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                    </div>
                                                    <div class="modal-body">

                                                        {
                                                            loading ?
                                                                <span>
                                                                    Loading...
                                                                </span>
                                                                :
                                                                <span>
                                                                    <b>
                                                                        {response}
                                                                    </b>
                                                                    <br />
                                                                    {/* <a href='/pages/contact'>
                                                                        Read more
                                                                    </a> */}
                                                                </span>
                                                        }
                                                    </div>
                                                    <div class="modal-footer">
                                                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        ""
                                }
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            {/* End Contact Section */}
        </div>
    )
}

export default Contact