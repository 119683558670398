
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import Trending from '../Components/Trending/Trending';
import { config } from '../Configuration/Api';
import WhatIsNew from '../Components/WhatIsNew';
import { SingleViewComponent } from './ViewDesignScreen';
import { setTitleAndDescription } from '../SEO/SeoFile';

function StitchingCollection() {
    //this is for all ready made woman products
    const [list, setList] = useState([])
    const location = useLocation();
    const { type } = location.state
    const params = useParams()
    const title = `${params.ctg} Designs - FashionWise Online Tailor Stitching service`
    const description = `All ${type} new ${params.ctg} designs for this season .Shop FashionWise
     online for the latest ${type}swear eastern designs in Pakistan. Stitching design ${type} for 
     ${params.ctg} category. Select your desired items and place an order; we will tailor them to your specifications.
      Moreover,all these design stitched by skilled tailors under the guidance of experienced designers.
       We are expert in ${type} stitching specially for ${params.ctg}.  `
    const keywords = `${type}, ${params.ctg} stitching service , ${params.ctg} designs ,${type} tailor , ${params.ctg} tailor,
    best ${params.ctg} tailor in lahore , online stitching ${params.ctg} for ${type} `
    useEffect(() => {

        setTitleAndDescription(title, description, keywords)
        axios
            .get(`${config.baseurl}/stitching/sub/all/${params.ctg}`)
            .then(data => {
                setList(data.data.Response)
            }

            )
            .catch(error => console.log(error));

    }, [params.ctg])

    return (
        <div style={{
            textAlign: "center"
        }}>

            {/* this is for what'is new collection */}
            <WhatIsNew leftSideTitle={`${type} Designs`} type={type} />


            <h3 style={{
                marginTop: "30px",
                marginBottom: "20px"
            }}>
                <span style={{
                    color: "#222222",
                    fontSize: "40px",
                    fontWeight: '500',
                    lineHeight: "30px",
                    textTransform: "capitalize"
                }}>
                    {params.ctg} collection
                </span>

            </h3>

            <div className='row mt-5 mb-5'>

                {list.map((item, index) => {
                    const actual = item.Actual_Price
                    const charge = item.Charge_Price
                    const discount = parseInt((actual - charge) * 100 / actual)
                    return (
                        <div className='col-6 col-sm-6 col-md-4 col-lg-3 mt-2 position-relative '>

                            <SingleViewComponent item={item} discount={discount} list={list} />
                        </div>
                    )
                }
                )}
            </div>
            <Trending type={type} />

        </div>
    )
}

export default StitchingCollection