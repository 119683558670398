

// this is for set the custom page title and description


export const setTitleAndDescription = (title,desc,keywords) => {
    document.title =title
    const descriptionElement = document.querySelector("meta[name='description']");
    descriptionElement.setAttribute('content', desc)
    const KeyWordsElement = document.querySelector("meta[name='keywords']");
    KeyWordsElement.setAttribute('content', keywords)

    const OgTitleElement = document.querySelector("meta[property='og:title']");
    OgTitleElement.setAttribute('content', title)
    const OgDescriptionElement = document.querySelector("meta[property='og:description']");
    OgDescriptionElement.setAttribute('content', desc)


    const twitterTitleElement = document.querySelector("meta[name='twitter:title']");
    twitterTitleElement.setAttribute('content', title)
    const twitterDescriptionElement = document.querySelector("meta[name='twitter:description']");
    twitterDescriptionElement.setAttribute('content', desc)
}