import React from 'react'

function FashionWiseApp() {
    return (
        <div>
            <div className="text-center pt-4 lead bg-light" style={{ height: 85 }}>
                <h2>FashionWise App</h2>
            </div>
            <div className="container mt-5 mb-5">

                <p className='text-center'>
                    FashionWise is a trailblazer in the garment services industry, boasting extensive experience in
                    crafting both ready-to-wear and custom clothing. Our FashionWise app offers a seamless and hassle-free
                    stitching experience, catering to those who are pressed for time and can't spare a moment for shopping.

                    One of our standout features is our one-day stitching service, setting the gold standard for efficiency.
                    While certain intricate designs may require more time to perfect, our remarkable delivery rate stands
                    at an impressive 99.99999%.</p>
                <br />
                <p className='text-center'>

                    We always urge to our customers to use FashionWise app for stitching serivce since it's user friendly and
                    fast way to get order track.
                    <br />
                </p>
                <h2 className='fw-bold text-start mt-2'>
                    How you can get FashionWise App ?
                </h2>
                <p className='fw-medium fs-22 lh-lg text-center'>
                    You need to install the FashionWise app which is available on both andriod and ios devices
                </p>
                <h4 className='fw-bold text-start mt-2'>
                    Andriod
                </h4>
                <p className='mt-1 text-start '>
                    <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold fs-4" />
                        Install for andriod user {"\t\t"}
                        <a href='https://play.google.com/store/apps/details?id=com.numanbuying.ourfashionwise&pcampaignid=web_share'>
                            Install</a>
                    </strong>
                </p>
                <h4 className='fw-bold text-start mt-2'>
                    IPhone
                </h4>
                <p className='mt-1 text-start '>
                    <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold fs-4" />
                        Install for IPhone/Apple user {"\t\t"}
                        <a href='https://apps.apple.com/pk/app/fashionwise/id1669561453'>
                            Install</a>
                    </strong>
                </p>
                <p className='text-center'>
                    In the fast-paced world of fashion, where trends come and go, FashionWise stands as a beacon of style,
                    quality, and convenience. As a trusted name in the industry, FashionWise brings forth a multitude
                    of features that make it your ultimate fashion destination. Here's why FashionWise deserves a prime
                    spot in your fashion journey:</p>
                <br />
                <h3>
                    Features of FashionWise
                </h3>
                <p>
                    i'm going to discuss few features of FashionWise App
                </p>
                <br />
                <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold fs-4" />
                    Custom Creations, Tailored for You
                </strong><br />
                <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold fs-4" />
                    Ready-to-Wear Elegance:
                </strong><br />
                <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold fs-4" />
                    Affordable Luxury:
                </strong><br />
                <p className='text-center text-uppercase fw-bold mt-5'>
                    While we've shared the essence of FashionWise here, the true experience awaits you after installing our app.
                    As they say, "practical experience speaks louder than written theory."

                    Download the FashionWise app today to immerse yourself in the world of fashion, discover
                    personalized style, and experience seamless convenience. Get ready to embark on a fashion
                    journey that's as unique as you are. Your fashion adventure begins with FashionWise.
                </p>
                <div />



            </div>
        </div>
    )
}

export default FashionWiseApp