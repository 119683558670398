import React from "react";

function Exchange() {
    return (
        <div style={{
            textAlign: "none"
        }}>
            <div className="container mt-3">
                <h2 >EXCHANGE POLICY</h2>
                <p >
                    At Fashionwise, our customers are our first priority. Our customer care
                    team will facilitate you on each step if you wish to exchange your
                    purchased item. The help you need is just a click away!{" "}
                </p>
                <h3 >Domestic Orders:</h3>
                <ul >
                    <li >
                        <p>
                            <b>Valid Days of Exchange:</b>
                            Any article purchased online can be exchanged from our stores as
                            well as online within 7 days, once the article has been
                            delivered, given that the customer has proof of purchase. For
                            customers who opt for online exchange process, with a valid reason
                            for exchange, won’t have to pay courier charges for exchange
                            pickup.
                        </p>
                    </li>
                    <li>
                        <p >
                            <b> Proof of Purchase:</b> This includes either a receipt or the
                            online order confirmation email.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b> Conditions Qualified for Exchange:</b> Articles are qualified
                            for change only if they are unused, all tags are intact, packing
                            is in its original condition and original invoice is present.
                            Also, state a valid reason for requesting an exchange, such as if
                            the article is defected, in wrong size, or incorrect. In case of
                            home article exchange, it should be in Fashionwise original home
                            packing. Similarly, with articles that are delivered in boxes such
                            as the Unstitched Women’s collection, Menswear and semi-formal
                            pieces that are delivered in garment bags must be returned with
                            their original packaging.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Process of Online Exchange:</b> It usually takes 7-10 days to
                            complete the online exchange process after it has been verified.
                            Instore exchanges require customers to have the original invoice
                            and all the tags intact for the purchased article. If customers
                            are dissatisfied with the item or have ordered and received the
                            size that won’t fit them then they would be asked to drop off the
                            purchased item at a given location. However, if the item is
                            defected or incorrect then the article will be picked up from the
                            customer’s doorstep.
                        </p>
                    </li>
                    {/* <li>
                        <p>
                            <b>Items not Allowed for Exchange:</b> Articles purchased on
                            discount or on sale are not qualified for exchange. Similarly,
                            Fragrances, Cosmetics and Intimate apparel cannot be exchanged.
                            Note: For sale orders, exchanges will only be provided for valid
                            reasons and exchange time will also take longer than usual. For
                            further assistance, you can contact our customer service
                            department by emailing us at wecare@Fashionwiseonline.pk or you can
                            call us at +92(0)42 111-738-245. Provide all the required
                            information to our representative and if all the conditions of the
                            exchange are met, we will schedule the pick-up from your doorstep.
                        </p>
                    </li> */}
                    {/* <li>
                        <p>
                            <b>Items not Allowed for Exchange:</b> Articles purchased on
                            discount or on sale are not qualified for exchange. Similarly,
                            Fragrances, Cosmetics and Intimate apparel cannot be exchanged.
                            Note: For sale orders, exchanges will only be provided for valid
                            reasons and exchange time will also take longer than usual. For
                            further assistance, you can contact our customer service
                            department by emailing us at wecare@Fashionwiseonline.pk or you can
                            call us at +92(0)42 111-738-245. Provide all the required
                            information to our representative and if all the conditions of the
                            exchange are met, we will schedule the pick-up from your doorstep.
                        </p>
                    </li> */}
                </ul>
                <h3>International Orders:</h3>
                <p>
                    Currently, Fashionwise does not have an Exchange/Return policy for
                    international orders. However, if you were delivered an incorrect or
                    damaged item then please contact our customer care department for
                    further help.{" "}
                </p>
                <p>
                    <b>Note:</b> Please take pictures of the package and invoice before
                    opening your order and keep them with you unless you find your
                    complete order intact. It will be helpful to resolve your complaints
                    timely, in case you receive an incorrect or damaged article.
                </p>
                <h3>Process of Online Exchanges</h3>
                <p>
                    You can now exchange your order online through our online portal for
                    exchange by logging into your Fashionwise Online account that you used to
                    place your order.{""} <br />
                    <br />
                    The Exchange validity period for the online exchange is 7 days.
                </p>
                <h3>RETURN POLICY</h3>
                <ul>
                    <li>
                        <p>
                            Fashionwise does not offer a return policy. However, we can guide you
                            in requesting an exchange for your article. Please refer to the
                            above-mentioned Exchange policy for this.
                        </p>
                    </li>
                    <li>
                        <p>
                            <b>Refund Policy:</b>
                            Any article purchased online can be exchanged from our stores as
                            well as online within 7 days, once the article has been
                            delivered, given that the customer has proof of purchase. For
                            customers who opt for online exchange process, with a valid reason
                            for exchange, won’t have to pay courier charges for exchange
                            pickup.
                        </p>
                    </li>
                </ul>
                <h3>ORDER CANCELLATION</h3>
                <ul>
                    <li>
                        <p>
                            Customers can cancel orders at any time before the order has been
                            processed.
                        </p>
                    </li>
                    <li>
                        <p>
                            Once the order has been shipped, you will receive a shipping
                            confirmation via email and an SMS with your COD amount as well as
                            order tracking ID. As soon as the product has been delivered, our
                            above-mentioned exchange policy will be applicable to your order.
                        </p>
                    </li>
                    <li>
                        <p>
                            Fashionwise holds the authority to cancel any order in cases, such
                            as; the item is out of stock, pricing errors, or the credit card
                            payment declined by the issuing financial institution.
                        </p>
                    </li>
                    <li>
                        <p>
                            Furthermore, you can click on this link to track your order:
                            <br />
                            <a href="/orders/tracking">Order tracking</a>
                        </p>
                    </li>
                </ul>
                {/* <h3>CHECKOUT (PAYPAL, AMEX, DISCOVER) RETURNS - TERMS & CONDITIONS:</h3>
        <ul>
          <li>
            <p>
              If damaged or incorrect items are delivered, please contact us at
              wecare@Fashionwiseonline.pk and provide the following for further
              evaluation.
              <ol>
                <li>Order Number</li>
                <li>Images of the delivered article with visible issue</li>
              </ol>
              <li>
                Fashionwise and 2Checkout have the right to refuse compensation or
                refund for damaged articles if this information is not provided.
              </li>
              <li>
                Orders dispatched or processed cannot be cancelled or edited.
              </li>
              <li>
                In case of quality issue identified while dispatching the order,
                Fashionwise will remove the concerned items from your original
                order. Refund will only be processed for items excluded from the
                order, not the entire value of the order.
              </li>
              <li>
                If the customer refuses to accept the order at the time of
                delivery, Fashionwise is not liable to refund or compensate for the
                order amount.
              </li>
              <li>
                If the Parcel is on hold by the Customs department of the
                shipping country, the customer is liable to provide all relevant
                and required documentation to the authorities. Fashionwise is not
                liable to refund the amount in case of non-clearance of the
                Parcel.
              </li>
            </p>
          </li>
        </ul> */}
            </div>
        </div>
    );
}

export default Exchange;
