import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import Trending from '../Components/Trending/Trending';
import { config } from '../Configuration/Api';
import WhatIsNew from '../Components/WhatIsNew';
import { setTitleAndDescription } from '../SEO/SeoFile';


const SingleViewComponent = ({ item, list, discount }) => {
    const navigate = useNavigate()
    const [hovered, setHovered] = useState(false)
    function MouseOver(event) {
        setHovered(true)
    }
    function MouseOut(event) {
        setHovered(false)
    }
    return (
        <div onClick={() => {
            navigate("/specific", { state: { single: item, allItems: list } })
        }} onMouseOver={MouseOver}
            onMouseOut={MouseOut} style={{
                position: "relative",
            }}>

            <picture>
                <source
                    media="(max-width: 768px)"
                    srcSet={`${item.Images[0]} 768w`}
                    sizes="768px"
                    width={"100%"}
                    height={"200vh"}
                />
                <source
                    srcSet={`${item.Images[0]} 1280w`}
                    sizes="1280px"
                    width={"100%"}
                    height={"500vh"}

                />
                <img src={item.Images[0]} />
            </picture>
            <picture style={{
                position: "absolute",
                top: 0,
                left: 0,
                opacity: 0,
                transition: `opacity 1s ease-in-out`,
                opacity: hovered ? 1 : 0
            }}>
                <source
                    media="(max-width: 768px)"
                    srcSet={`${item.Images[2]} 768w`}
                    sizes="768px"
                    width={"100%"}
                    height={"200vh"}
                />
                <source
                    srcSet={`${item.Images[2]} 1280w`}
                    sizes="1280px"
                    width={"100%"}
                    height={"500vh"}

                />
                <img src={item.Images[2]} />
            </picture>


            <span style={{

                textTransform: "capitalize",
                color: "#222222",
                fontSize: "2.5vh",
                fontWeight: '500',
            }}>
                {item.Name}
            </span>


            <div className='row '>
                <span style={{
                    textTransform: "capitalize",
                    color: "#222222",
                    fontSize: "22px",
                    fontWeight: '500',
                    lineHeight: "30px",
                }} className='col-sm-12 col-md-6' >
                    Rs:{item.Charge_Price}
                </span>
                <span style={{
                    textTransform: "capitalize",
                    color: "#222222",
                    fontSize: "16px",
                    lineHeight: "30px",
                    textDecorationLine: "line-through"
                }} className='col-sm-12 col-md-6'>
                    {item.Actual_Price}.00 off
                </span>
            </div>

        </div>
    )
}
function ViewDesignScreen() {
    //this is for all ready made woman products
    const [list, setList] = useState([])
    const params = useParams()
    const title = `Best ${params.type} Tailor in Lahore for shalwar kameez suit`
    const description = `All ${params.type} new  clothing designs for this stitching service .  You have the flexibility to 
         customize  clothing design according to your need or your liking . We will tailored them according to that .
          Moreover,all   these design stitched by skilled tailors under the guidance of experienced designers. We are expert in 
         ${params.type} stitching  .Best tailor service in town `
    const keywords = `${params.type} Designs , best tailor for ${params.type} ,online tailor for ${params.type},
    stitching designs for ${params.type}, best ${params.type} tailor in lahore, designer clothes for ${params.type} ,
     best suit tailor in lahore , ${params.type} Online Clothing`
    useEffect(() => {

        setTitleAndDescription(title, description, keywords)
        axios
            .get(`${config.baseurl}/stitching/sub/all/products`)
            .then(data => {
                if (params.type == "all") {
                    setList(data.data.Response)
                }
                else {
                    const filterList = data.data.Response?.filter((item) => item.main_category.general_category.Name == params.type);
                    setList(filterList)
                }

                window.scrollTo(0, 0);
            }

            )
            .catch(error => console.log(error));

    }, [])
    return (
        <div style={{
            textAlign: "center"
        }}>

            {/* this is for what'is new collection */}
            <WhatIsNew leftSideTitle={`${params.type} Designs`} type={params.type} />

            <h3 style={{
                marginTop: "30px",
                marginBottom: "20px",
                textAlign: "center"
            }}>
                <span style={{
                    color: "#222222",
                    fontSize: "40px",
                    fontWeight: '500',
                    lineHeight: "30px",
                    textTransform: "capitalize"
                }}>
                    {params.type} stitching Design
                </span>

            </h3>

            <div className='row mt-5 mb-5'>


                {list.map((item, index) => {
                    const actual = item.Actual_Price
                    const charge = item.Charge_Price
                    const discount = parseInt((actual - charge) * 100 / actual)
                    return (
                        <div className='col-6 col-sm-6 col-md-4 col-lg-3 mt-2 position-relative '>

                            <SingleViewComponent item={item} discount={discount} list={list} />
                        </div>
                    )
                }
                )}
            </div>

            <Trending type={params.type} />

        </div>
    )
}

export { ViewDesignScreen, SingleViewComponent }