import React from 'react'
import SpecialRequestChooseCategory from '../Assests/Images/SpecialRequestChooseCategory.js.jpg'
import SpecialRequestChooseSpecialRequestjs from '../Assests/Images//SpecialRequestChooseSpecialRequestjs.jpg'
function SpecialRequestPage() {   
    window.scrollTo(0, 0);
    return (
        <div className='mt-3 container'>
            <h1 className='fw-bold text-start'>
                Special Design Request
            </h1>
            <p className='fw-medium fs-22 lh-lg'>
                Select Your Unique Style with FashionWise
                <br />
                At FashionWise, we empower our customers to embrace their individual style. We believe that true
                fashion is about expressing your unique personality and letting it shine through what you wear.

                Our specialty lies in creating custom designs that cater to your preferences and admiration.
                Our goal is to bring smiles to our customers' faces by offering clothing designs that truly reflect
                their personal choices.
            </p>

            <h2 className='fw-bold text-start mt-2'>
                How you can get this feature ?
            </h2>
            <p className='fw-medium fs-22 lh-lg'>
                You need to install the FashionWise app which is available on both andriod and ios devices
            </p>
            <h4 className='fw-bold text-start mt-2'>
                Andriod
            </h4>
            <p className='mt-1 '>
                <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold fs-4" />
                    Install for andriod user {"\t\t"}
                    <a href='https://play.google.com/store/apps/details?id=com.numanbuying.ourfashionwise&pcampaignid=web_share'>
                        Install</a>
                </strong>
            </p>
            <h4 className='fw-bold text-start mt-2'>
                IPhone
            </h4>
            <p className='mt-1 '>
                <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold fs-4" />
                    Install for IPhone/Apple user {"\t\t"}
                    <a href='https://apps.apple.com/pk/app/fashionwise/id1669561453'>
                        Install</a>
                </strong>
            </p>

            <h4 className='fw-bold text-start mt-2'>
                Procedure
            </h4>
            <p className='mt-1 '>
                Placing an special request order is very easy and fast.After successfully downloading the FashionWise
                App you can place an order with only two steps .
                <br />
                <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold fs-4" />
                    On the main Dashboard
                </strong>
                <br />
                <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold fs-4" />
                    Click the Categories (Man,Women,Kids) whatever you want to choose
                </strong>

            </p>
            <div className='mx-auto  w-50 ' >

                <picture >
                    <source
                        media="(max-width: 768px)"
                        srcSet={`${SpecialRequestChooseCategory} 768w`}
                        sizes="768px"
                        width={"100%"}
                        height={"100%"}
                    />
                    <source
                        srcSet={`${SpecialRequestChooseCategory} 1280w`}
                        sizes="1280px"
                        width={"100%"}
                        height={"600px"}

                    />
                    <img src={SpecialRequestChooseCategory}
                    />
                </picture>
            </div>


            <strong className='text-center container '> <i className="bi bi-dot fw-bold fs-4" />
                After select the category you will see this screen  . At this screen you have to click the option
                special request for Placing the special request order and then follow the further step to complete
                teh request.
            </strong>
            <br />
            <div className='mx-auto  w-50 ' >

                <picture >
                    <source
                        media="(max-width: 768px)"
                        srcSet={`${SpecialRequestChooseSpecialRequestjs} 768w`}
                        sizes="768px"
                        width={"100%"}
                        height={"100%"}
                    />
                    <source
                        srcSet={`${SpecialRequestChooseSpecialRequestjs} 1280w`}
                        sizes="1280px"
                        width={"100%"}
                        height={"600px"}

                    />
                    <img src={SpecialRequestChooseSpecialRequestjs}
                    />
                </picture>
            </div>
            <p className='mt-1 '>
                After this you have to create the account if you not created yet!
                <br />
                <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold fs-4" />
                    select your gender of special order request
                </strong>
                <br />
                <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold fs-4" />
                    Pick the near suitable category/design request
                </strong>
                <br />
                <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold fs-4" />
                    Write about your request that what you want exactly from us to stitch for you.
                </strong>
                <br />
                <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold fs-4" />
                    Finally! click on the Submit button .
                </strong>
            </p>
            <p className='text-center fs-5 text-capitalize fw-bold text-success lh-2'>

                You successfully submit the design request
            </p>
        </div>
    )
}

export default SpecialRequestPage