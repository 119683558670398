const config = {
    // baseurl:'http://10.0.2.2:4000'
    // baseurl: 'http://127.0.0.1:4009'
    //baseurl : 'https://fashionapp.herokuapp.com'
    // baseurl : 'https://dressmeapp.herokuapp.com'
    baseurl: "https://fashionwise.herokuapp.com"
}

export {
    config
}