import React from 'react'
import { setTitleAndDescription } from '../SEO/SeoFile';
import { useEffect } from 'react';
import { callToGetBlogs } from '../Configuration/ApiIntegration';
import { useState } from 'react';

function Blogs() {
    window.scrollTo(0, 0);
    setTitleAndDescription(`FashionWise Blogs & Trends `,
        `Explore our blog section for updates, 
    trends new products for men , women and kids  including shalwar kameez design , women clothes design for 
    western and traditional wear in Pakistan.`,
        `FashionWise Blogs , Trends , FashionWise Best Online Stitching Service In Lahore , FashionWise Articles`
    )
    const [articles, setArticles] = useState([])
    async function getBlogs() {
        const response = await callToGetBlogs("all")
        setArticles(response.blogs)
    }
    useEffect(() => {
        getBlogs()
    }, [])
    return (
        <div>
            <div className="text-center pt-4 fw-bold bg-light " style={{ height: 85 }}>
                <h2 className='fw-bold'>Stitching Design Guide Article</h2>
            </div>
            <div className='container'>
                <div className='row mt-5 mb-5'>

                    {
                        articles?.map((item) => {
                            return (
                                <div className='col-sm-12 col-md-6 col-lg-4 mt-3 '>
                                    <a href={`/pages/design/guide/${item._id}`}>
                                        <img src={item.poster[0]} width={"100%"} height={"250px"} />
                                    </a>
                                    <h6 className='text-center pt-3 text-uppercase fw-bold '>
                                        {item.title}
                                    </h6>
                                    <p className='text-center pt-3  '>
                                        {item.summary}
                                    </p>

                                    <a className="text-center d-block text-black icon-link icon-link-hover" href={`/pages/design/guide/${item._id}`}>
                                        Continue Reading {"\t\t"}
                                        <i className="bi bi-arrow-right fs-20 icon-link icon-link-hover"></i>
                                    </a>

                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Blogs