import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react'
import { Link } from 'react-router-dom';
import { OrderStatus } from '../Configuration/ApiIntegration';

function OrdersRecord() {

    const [empty, setEmpty] = useState(false)
    const [list, setList] = useState()
    const [loading, setLoading] = useState(false)
    const [presentStatus, setPresentStatus] = useState(false)
    const [number, setNumber] = useState()
    useEffect(() => {
        const orders = localStorage.getItem("orders");
        console.log(orders);
        if (!orders) {
            console.log(orders);
            setEmpty(true);
            return
        }
        const data = JSON.parse(orders)
        setList(data)
        console.log(data);

    }, [])

    const showStatus = async (number) => {
        setLoading(true)
        console.log(number);
        const order = await OrderStatus(number);
        if (order.success == true) {
            setLoading(false)
            setPresentStatus(order.Response.status)
            setNumber(order.Response.number)
        }
    }
    return (
        <div>

            {
                empty ?
                    <div>
                        <h3 style={{
                            marginTop: "30px",
                            marginBottom: "20px",
                            textAlign: "center"
                        }}>
                            <span style={{
                                color: "#222222",
                                fontSize: "30px",
                                fontWeight: "bold",
                                lineHeight: "30px",
                                textTransform: "capitalize",
                                textAlign: "center"
                            }}>
                                You does not have any order yet
                            </span>

                        </h3>
                        <div className="container text-center mt-5 mb-5">
                            {/* image will display here */}

                            <div />
                        </div>
                    </div>
                    :
                    <div>
                        <p style={{
                            color: "#222222",
                            fontSize: "30px",
                            fontWeight: "bold",
                            lineHeight: "30px",
                            textTransform: "capitalize",
                            textAlign: "center",
                            margin: "auto"
                        }} className="mt-3 mb-3">
                            your order history
                        </p>
                        {
                            list?.map((item, index) => {
                                return (
                                    <div key={index} className="bg-light mt-3 container" >

                                        <div className='row'>
                                            <div className='col-4'>
                                                <img src={item.Product.Images[0]} width='100%' height={250} />
                                            </div>
                                            <div className='col-8'>
                                                <div className='row row-cols-auto'>
                                                    <span style={{
                                                        textTransform: "capitalize",
                                                        color: "#222222",
                                                        fontSize: "25px",
                                                        fontWeight: '700',
                                                        display: "flex",

                                                    }} className="col">
                                                        {item.Product.Name}
                                                    </span>
                                                    {/* <span style={{
                                                        textTransform: "capitalize",
                                                        color: "grey",
                                                        fontWeight: "600",
                                                        marginTop: 10,
                                                        // lineHeight: "2",
                                                    }} className='col'>
                                                        (Design)
                                                    </span> */}
                                                </div>

                                                <div className='row row-cols-auto'>
                                                    <span style={{
                                                        textTransform: "capitalize",
                                                        color: "darkgray",
                                                        fontWeight: '700',
                                                        display: "flex",

                                                    }} className="col">
                                                        SKU# :
                                                    </span>
                                                    <span style={{
                                                        textTransform: "capitalize",
                                                        color: "darkgray",
                                                    }} className='col'>
                                                        F-WSD001
                                                    </span>
                                                </div>

                                                <div className='row row-cols-auto'>
                                                    <span style={{
                                                        textTransform: "capitalize",
                                                        color: "gray",
                                                        fontWeight: '700',
                                                        display: "flex",

                                                    }} className="col">
                                                        OrderNumber:
                                                    </span>
                                                    <span style={{
                                                        textTransform: "capitalize",
                                                        color: "gray",
                                                        fontWeight: "bold"
                                                    }} className='col'>
                                                        {item.OrderNumber}
                                                    </span>
                                                </div>


                                                <div className=' mt-2 row-col-auto' style={{ display: "flex" }}>
                                                    <span style={{
                                                        textTransform: "capitalize",
                                                        color: "#222222",
                                                        fontSize: `3vh`,
                                                        fontWeight: 'bold',
                                                        display: 'flex'
                                                        // lineHeight: "22",


                                                    }} >
                                                        OrderPrice:
                                                    </span>
                                                    <span style={{
                                                        textTransform: "capitalize",
                                                        color: "grey",
                                                        fontSize: "3vh",
                                                        fontWeight: '700',
                                                        display: 'flex',
                                                        // lineHeight: "",
                                                        marginLeft: 15,
                                                        // textDecorationLine: "line-through"

                                                    }} >
                                                        PKR {item.Product.Charge_Price}.00
                                                    </span>

                                                </div>
                                                <div style={{
                                                    display: "flex",
                                                    marginTop: 5
                                                }}>
                                                    <span style={{
                                                        textTransform: "capitalize",
                                                        color: "white",
                                                        fontSize: "18",
                                                        fontWeight: 'bold',
                                                        lineHeight: "30px",
                                                        backgroundColor: "grey",
                                                        borderRadius: 5,
                                                        paddingRight: 15,
                                                        paddingLeft: 15
                                                    }}>
                                                        {/* {discount}% off */}
                                                    </span>
                                                </div>
                                                <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#staticBackdro" onClick={() => {
                                                    showStatus(item.OrderNumber)
                                                }}>
                                                    Check Status
                                                </button>


                                                <div class="modal fade" id="staticBackdro" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                                                    <div class="modal-dialog">
                                                        <div class="modal-content">
                                                            <div class="modal-header">
                                                                <h5 class="modal-title" id="staticBackdropLabel">Order Status</h5>
                                                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                                            </div>
                                                            <div class="modal-body">

                                                                {
                                                                    loading ?
                                                                        <span>
                                                                            Loading...
                                                                        </span>
                                                                        :
                                                                        <span>
                                                                            Your order  <b>{number}</b> current status is <b>{presentStatus}</b>
                                                                            <br />
                                                                            <a href='/pages/contact'>
                                                                                Read more
                                                                            </a>
                                                                        </span>
                                                                }
                                                            </div>
                                                            <div class="modal-footer">
                                                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Ok</button>
                                                                {/* <button type="button" class="btn btn-primary" onClick={() => {
                                                                    // <Link to={"/pages/contact"} />
                                                                }}></button> */}
                                                                <a href='/pages/contact' style={{
                                                                    backgroundColor: "royalblue",
                                                                    padding: 5,
                                                                    color: "white",
                                                                    fontWeight: "bold",
                                                                    textDecorationLine: "none"
                                                                }}>
                                                                    Contact US
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                )
                            })
                        }
                    </div>
            }
        </div>
    )
}

export default OrdersRecord