import React from 'react'
import { setTitleAndDescription } from '../SEO/SeoFile'

function AboutUs() {
  setTitleAndDescription("FashionWise About Us ",
    `FashionWise provides online and in-store stitching services . You can visit our booking offices 
and order online.For FashionWise providing affordable designer clothes to the masses is very important `
    , `FashionWise About Us, thefashionwise.com, fashionwise , 
what is fashionwise  , online tailor information , Online tailor in lahore`)
  return (
    <div>
      <div className="text-center pt-4 lead bg-light" style={{ height: 85 }}>
        <h2> ABOUT US</h2>
      </div>
      <div className="container text-center mt-5 mb-5">
        <h1>FASHIONWISE</h1>
        <p>A high-street brand introduced by one of the iconic names in the fashion design industry,
          FashionWise is celebrated for combining 100% pure fabric with unprecedented design aesthetic
          to create designer wear at an affordable price. Our range of clothing design has something for
          everyone: Daily wear, party wear, formal wear, silk tunics, and scarves too.
          The overarching theme of the collections is providing affordable designer clothes to the masses.</p>
        <br />
        <p>This meld of exquisite design and high class fabric has resulted in the breakthrough of this one
          of a kind fashion design brand. FashionWise's commitment to unique and eye-catching design over the years
          has made it a household name, synonymous with excellence. We hope you enjoy wearing them as much we love
          making them for you!</p>
        <div />
      </div>
    </div>
  )
}

export default AboutUs