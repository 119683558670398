import React from 'react'
import { privacyPolicy } from '../Utilis/FAQSQuestion'

function PrivacyPolicy() {
    return (
        <div className='mt-3 container'>
            <h1 className='fw-bold text-start'>
                Privacy Policy
            </h1>
            <p className='fw-medium fs-22 lh-lg'>
                Last updated: September 25, 2023<br />
                This Privacy Policy describes Our policies and procedures on the collection, use and disclosure
                of Your information when You use the Service and tells You about Your privacy rights and how the law
                protects You.

                We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
                collection and use of information in accordance with this Privacy Policy.
            </p>
            <h1 className='fw-bold text-start mt-2'>
                Interpretation and Definitions
            </h1>

            <h2 className='fw-bold text-start mt-2'>
                Interpretation
            </h2>
            <p className='fw-medium fs-22 lh-lg'>
                The words of which the initial letter is capitalized have meanings defined under the following conditions.
                The following definitions shall have the same meaning regardless of whether they appear in singular or in plural.
            </p>


            {
                privacyPolicy.map((item, index) => (
                    <div className="mt-2">
                        <h2 className='fw-bold text-start mt-2'>
                            {item.question}
                        </h2>
                        <p className='text-capitalize'>
                            {
                                item.shortAnswer
                            }
                        </p>
                        {
                            item.answer.map((q) => (
                                <p className='mt-1 '>
                                    <strong className='ps-2 pe-2'> <i className="bi bi-dot fw-bold" style={{
                                        fontSize: 20
                                    }} />{q.heading}</strong>
                                    {q.text}
                                </p>
                            ))
                        }
                    </div>
                ))
            }

            <h2 className='fw-bold text-start mt-2'>
                Retention of Your Personal Data
            </h2>
            <p className='fw-medium fs-22 lh-lg'>
                The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
                this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our
                legal obligations (for example, if we are required to retain your data to comply with applicable laws),
                resolve disputes, and enforce our legal agreements and policies.
                The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a
                shorter period of time, except when this data is used to strengthen the security or to improve the functionality
                of Our Service, or We are legally obligated to retain this data for longer time periods.
            </p>
            <h2 className='fw-bold text-start mt-2'>
                Changes to this Privacy Policy
            </h2>
            <p className='fw-medium fs-22 lh-lg'>
                We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new
                Privacy Policy on this page.
                We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective
                and update the "Last updated" date at the top of this Privacy Policy.
                You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are
                effective when they are posted on this page.
            </p>
            <h2 className='fw-bold text-start mt-2'>
                Contact Us
            </h2>
            <p className='fw-medium fs-22 lh-lg'>
                If you have any questions about this Privacy Policy, You can contact us:
                <br />
                By email: wecare@thefashionwise.com
            </p>
        </div>
    )
}

export default PrivacyPolicy