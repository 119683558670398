

export const faqs = [
    {
        question: " What kind of services does  FashionWise offer? ",
        shortAnswer: `As a tailoring brand FashionWise offer large range of services in stitching category to provide
        customers hassle free stitching online experience .`,
        answer: [
            {
                heading: "Dressmaking:",
                text: " We are specialize in creating custom dresses for special occasions like weddings, proms, or formal events."
            },
            {
                heading: "Consultations:",
                text: "We offer consultations to discuss style preferences, fabric choices, and design options with clients."
            },
            {
                heading: "Alterations:",
                text: `Alteration services involve adjusting the fit and style of existing clothing to ensure it fits perfectly.
                This can include hemming pants,taking in or letting out waistlines, adjusting sleeve lengths, and more.
                Moreover ,this service is specially for those garments that were stitched by FashionWise`
            },
            {
                heading: "Measurement Services: ",
                text: `We offer professional measurement services, particularly for online shoppers who need
                 accurate measurements for ordering custom clothing.We have both facility for taking measurement 
                 at customer doorstep and also in FashionWise booking office.`
            },
            {
                heading: "Professional Advice:",
                text: `We provide expert advice on clothing fit, style, and fabric choices to help 
                clients look their best.`
            },
            {
                heading: " Wedding Attire:",
                text: `We offer services for brides, grooms, and bridal parties,
                      including wedding gown alterations and tuxedo fittings.Moreover ,this service is
                      specially for those garments that were stitched by FashionWise`
            }
        ]
    },
    {
        question: "How we can order at fashionWise ?",
        shortAnswer: `Placing an order at fashionWise is very simple you just need to select the design that you want to
         be stitch . Enter your details and rest of the work leave to us!` ,
        answer: []
    },
    {
        question: "How to contact ? ",
        shortAnswer: `You can contact us anywhere you want. We are available on every 
        social media platform and try to stay in touch with our customers. ` ,
        answer: [
            {
                heading: "WhatsApp:",
                text: "Our WhatsApp number is +92 326 432 1272"
            },
            {
                heading: "Phone Call:",
                text: "You can call us at our helpline 0326 4321272"
            },
            {
                heading: "Emails",
                text: `We encourge our customers to send us emails at wecare@thefashionwise.com`
            },
            {
                heading: "Contact Form",
                text: `You can contact us at our website which is a very fast procesing way of 
                communication at fashionWise. Just click on the Contact-Us tab.`

            },
            {
                heading: "Facebook & Instagram",
                text: `We are also availble at Facebook you can hit us message at messanger as well`
            },

        ]
    },
    {
        question: "Office Location ",
        shortAnswer: `We have an booking offices . You can visit our booking-office page  to get the more details about 
        our store booking process. Booking-office link available at the bootom of this page ` ,
        answer: []
    },
    {
        question: "Order Tracking ",
        shortAnswer: `You can keep track of your order by just clicking the page at bottom Order Tracking . You need
        to enter you order code that you received in email . Order code should be into this format SON001233 ` ,
        answer: [{
            heading: "Order Status",
            text: "You will view different kind of order status such as your order is ordered,confirmed,completed,delivered,cancelled",
        },
        {
            heading: "Ordered",
            text: "This status mean that your order has been placed and need to wait from our representative call.",
        },
        {
            heading: "Confirmed",
            text: "After confirmation call . We update the order status from ordered to confirmed . this mean that our team start working on that order.",
        },
        {
            heading: "Completed",
            text: `Once your order prcessing is complete . your order status updated to completed. After final check we
            dispatch your order.`,
        },
        {
            heading: "Delivered",
            text: "Finally, When you received your order then we update the status to delivered .",
        },
        {
            heading: "Cancelled",
            text: "If your order is cancelled without your confirmation then you can contact us . We will resume is again!",
        },
        ]
    },
    {
        question: "About US ",
        shortAnswer: `A high-street brand introduced by one of the iconic names in the fashion design industry, 
        FashionWise is celebrated for combining 100% pure fabric with unprecedented design aesthetic to create
         designer wear at an affordable price. Our range of clothing design has something for everyone: Daily wear, 
         party wear, formal wear, silk tunics, and scarves too. The overarching theme of the collections is providing
          affordable designer clothes to the masses.`,



        answer: []
    },
    {
        question: "Security ",
        shortAnswer: `FashionWise order making process is very secure and you will not see any kind of mistake .since our 
        all staff is professional form Rider to cutting master and tailor . All are experienced and best in their field .
        Feel free to order .` ,

        answer: [{
            heading: "1. Why is it mandatory to give personal information?",
            text: `Customer information pertaining to name, emial and shipping addresses are necessary 
            to process any online confirmation. These are essential inputs into the fraud detection mechanisms.
            `
        },
        {
            heading: "2. Is my personal information kept secured?",
            text: `yes, you can place an order with us without any hesitation all your personal informations are secured with us.
        We at FashionWise never compromise on the security of the personal details of our beloved customers.`,
        },
        {
            heading: "3. Will I receive online security email every time I place an order with you ?",
            text: `Although, online security is implied for each order and the confirmation procedure is one-time for account
         information, if you are utilizing a different email, your request will be subjected to online security confirmation
          once more.
        `
        },
        {
            heading: "4. What are cookies and why should I know about them?",
            text: ` A cookie is a small text file that is saved to, and, during subsequent visits, retrieved from
         your computer or mobile device.We use cookies to enhance and simplify your visit.We do not use cookies
          to store personal information or to disclose information to third parties but rather to store your choice
           of start page and to store your details.Session cookies are used when you apply product filtration and 
           to check if you are logged in.You can easily erase cookies from your computer or mobile device using
            your browser.`,
        },
        {
            heading: "Delivered",
            text: "Finally, When you received your order then we update the status to delivered .",
        },
        {
            heading: `Note: Please do not accept the parcel if it's attached with the official bill.
        Kindly only accept parcels that are packed in FashionWIse branded flyers.`,
            text: "",
        },
        ]
    },
    {
        question: "Delivery",
        shortAnswer: `Our Standard delivery time is 1 day but we generally give customer 3 days for deliver the orders. If your
        order is special request or some complex design then it can take more time to 3 days .We will informed the customers
        delivery time before confirming the orders but if you need your fabric urgently then we will deliver within 24 hours ` ,

        answer: []
    }
]

export const privacyPolicy = [
    {
        question: "Definitions",
        shortAnswer: `For the purposes of this Privacy Policy:`,
        answer: [{
            heading: "Account ",
            text: "means a unique account created for You to access our Service or parts of our Service.",
        },
        {
            heading: "Affiliate ",
            text: "means an entity that controls, is controlled by or is under common control with a party, where control means ownership of 50% or more of the shares, equity interest or other securities entitled to vote for election of directors or other managing authority.",
        },
        {
            heading: "Application",
            text: "refers to FashionWise, the software program provided by the Company.",
        },
        {
            heading: "Company",
            text: `(referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to
             FashionWise Technologies, 70 D khyber block Allama iqbal town lahore.`,
        },
        {
            heading: "Country",
            text: "refers to: Pakistan",
        },
        {
            heading: "Device",
            text: "means any device that can access the Service such as a computer, a cellphone or a digital tablet.",
        },
        ]
    },
    {
        question: "Personal Data ",
        shortAnswer: `While using Our Service, We may ask You to provide Us with certain personally identifiable information 
        that can be used to contact or identify You. Personally identifiable information may include, but is not 
        limited to:`,
        answer: [{
            heading: "Email address",
            text: "",
        },
        {
            heading: "First name and last name ",
            text: "",
        },
        {
            heading: "Phone number",
            text: "",
        },
        {
            heading: "Address, State, Province, ZIP/Postal code, City",
            text: ``,
        },
        {
            heading: "Usage Data",
            text: "",
        },
        ]
    },
    {
        question: "Usage Data",
        shortAnswer: `Usage Data is collected automatically when using the Service.
        Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
        browser type, browser version, the pages of our Service that You visit, the time and date of Your visit,
         the time spent on those pages, unique device identifiers and other diagnostic data.
        When You access the Service by or through a mobile device, We may collect certain information automatically,
         including, but not limited to, the type of mobile device You use, Your mobile device unique ID, 
         the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet browser You use, 
         unique device identifiers and other diagnostic data.
        We may also collect information that Your browser sends whenever You visit our Service or when You access 
        the Service by or through a mobile device.`,
        answer: [{
            heading: "To provide and maintain our Service",
            text: "including to monitor the usage of our Service.",
        },
        {
            heading: "To manage Your Account:",
            text: `to manage Your registration as a user of the Service. The Personal Data You provide 
            can give You access to different functionalities of the Service that are available to You as a registered user.`,
        },
        {
            heading: "For the performance of a contract:",
            text: `the development, compliance and undertaking of the purchase contract for the products,
             items or services You have purchased or of any other contract with Us through the Service.`,
        },
        {
            heading: "To contact You:",
            text: ` To contact You by email, telephone calls, SMS, or other equivalent forms of electronic 
            communication, such as a mobile application's push notifications regarding updates or informative
             communications related to the functionalities, products or contracted services, including the security
              updates, when necessary or reasonable for their implementation.`,
        },
        {
            heading: "To provide You",
            text: `with news, special offers and general information about other goods, services and events which we 
            offer that are similar to those that you have already purchased or enquired about unless You have 
            opted not to receive such information.`,
        },
        {
            heading: "To manage Your requests: ",
            text: ` To attend and manage Your requests to Us.`,
        },
        {
            heading: "For business transfers:",
            text: `We may use Your information to evaluate or conduct a merger, divestiture, restructuring, 
            reorganization, dissolution, or other sale or transfer of some or all of Our assets, whether as
             a going concern or as part of bankruptcy, liquidation, or similar proceeding, in which Personal Data 
             held by Us about our Service users is among the assets transferred.`,
        },
        ]
    },
]

