import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { useNavigate } from 'react-router-dom'

import bannerMobile1 from "../../Assests/MainPosters/MobileBanner0.jpg"
import bannerMobile2 from "../../Assests/MainPosters/MobileBanner1.jpg"
import bannerMobile3 from "../../Assests/MainPosters/MobileBanner2.jpg"
import bannerMobile4 from "../../Assests/MainPosters/MobileBanner3.jpg"

import bannerWeb1 from "../../Assests/MainPosters/DesktopBanner0.jpg"
import bannerWeb2 from "../../Assests/MainPosters/DesktopBanner1.jpg"
import bannerWeb3 from "../../Assests/MainPosters/DesktopBanner2.jpg"
import bannerWeb4 from "../../Assests/MainPosters/DesktopBanner3.jpg"
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './style.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

function MainCrousel() {
    const progressCircle = useRef(null);
    const progressContent = useRef(null);
    const navigate = useNavigate();
    const onAutoplayTimeLeft = (s, time, progress) => {
        progressCircle.current.style.setProperty('--progress', 1 - progress);
        progressContent.current.textContent = `${Math.ceil(time / 1000)}s`;
    };

    const allView = (param) => {
        console.log("click");
        navigate(`/stitching-design/${param}`)
    }

    return (
        <>
            <Swiper
                spaceBetween={30}
                centeredSlides={true}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                pagination={{
                    clickable: true,
                }}
                // navigation={true}
                modules={[Autoplay, Pagination, Navigation]}
                onAutoplayTimeLeft={onAutoplayTimeLeft}
                className="mySwiper"
            >
                <SwiperSlide onClick={() => allView("all")}>
                    <picture >
                        <source
                            media="(max-width: 768px)"
                            srcSet={`${bannerMobile1} 768w`}
                            sizes="768px"
                            width={"100%"}
                            height={"100%"}

                        />
                        <source
                            srcSet={`${bannerWeb1} 1280w`}
                            sizes="1280px"
                            width={"100%"}
                            height={"100%"}
                        />
                        <img src={bannerMobile1} style={{ width: "100%", height: '100%' }} />
                    </picture>
                </SwiperSlide>
                <SwiperSlide onClick={() => allView("Man")}>
                    <picture >
                        <source
                            media="(max-width: 768px)"
                            srcSet={`${bannerMobile2} 768w`}
                            sizes="768px"
                            width={"100%"}
                        // height={"100%"}

                        />
                        <source
                            srcSet={`${bannerWeb2} 1280w`}
                            sizes="1280px"
                            width={"100%"}
                            height={"100%"}
                        />
                        <img src={bannerMobile2} style={{ width: "100%", height: '100%' }} />
                    </picture>
                </SwiperSlide>
                <SwiperSlide onClick={() => allView("Women")}>
                    <picture >
                        <source
                            media="(max-width: 768px)"
                            srcSet={`${bannerMobile3} 768w`}
                            sizes="768px"
                            width={"100%"}
                        // height={"100%"}

                        />
                        <source
                            srcSet={`${bannerWeb3} 1280w`}
                            sizes="1280px"
                            width={"100%"}
                            height={"100%"}
                        />
                        <img src={bannerMobile3} style={{ width: "100%", height: '100%' }} />
                    </picture>
                </SwiperSlide>
                <SwiperSlide onClick={() => allView("all")}>
                    <picture >
                        <source
                            media="(max-width: 768px)"
                            srcSet={`${bannerMobile4} 768w`}
                            sizes="768px"
                            width={"100%"}
                        // height={"100%"}

                        />
                        <source
                            srcSet={`${bannerWeb4} 1280w`}
                            sizes="1280px"
                            width={"100%"}
                            height={"100%"}
                        />
                        <img src={bannerMobile4} style={{ width: "100%", height: '100%' }} />
                    </picture>
                </SwiperSlide>

                <div className="autoplay-progress" slot="container-end">
                    <svg viewBox="0 0 48 48" ref={progressCircle}>
                        <circle cx="24" cy="24" r="20"></circle>
                    </svg>
                    <span ref={progressContent}></span>
                </div>
            </Swiper>
        </>
    )
}

export default MainCrousel