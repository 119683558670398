import React, { useEffect, useRef } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import MobileMan from '../Assests/Images/MobileMan.jpg'
import DesktopMan from '../Assests/Images/DesktopMan.jpg'
import DesktopManHover from '../Assests/Images/DesktopManHover.jpg'

import MobileKids from '../Assests/Images/MobileKIds.jpg'
import DesktopKids from '../Assests/Images/DesktopKids.jpg'
import DesktopKidsHover from '../Assests/Images/DesktopKidsHover.jpg'

import MobileWomen from '../Assests/Images/MobileWomen.jpg'
import DesktopWomen from '../Assests/Images/DesktopWomen.jpg'
import DesktopWomenHover from '../Assests/Images/DesktopWomenHover.jpg'






function Design() {

    const navigate = useNavigate()
    const [hovered, setHovered] = useState(false)
    const [imageHover, setImageHover] = useState()
    const [manhovered, mansetHovered] = useState(false)
    const [menImage, setMenImage] = useState()
    const [womenImage, setwomenImage] = useState()
    const [kidsImage, setKidsImage] = useState()
    const [kidsHover, setKidsHover] = useState(false)
    function MouseOverMan(event) {
        // event.target.style.backgroundColor = "black";
        setMenImage(DesktopManHover)
        mansetHovered(true)
    }
    function MouseOutMan(event) {
        // event.target.style.backgroundColor = 'grey';
        mansetHovered(false)
        setMenImage(DesktopMan)
        mansetHovered(false)
    }

    function MouseOverWomen(event) {
        // event.target.style.backgroundColor = "black";
        setwomenImage(DesktopWomenHover)
        setHovered(true)
    }
    function MouseOutWomen(event) {
        // event.target.style.backgroundColor = 'grey';
        setHovered(false)
        setwomenImage(DesktopWomen)
        setHovered(false)
    }
    function MouseOver(event, image) {
        setKidsHover(true)
        setKidsImage(DesktopKidsHover)


    }
    function MouseOut(event, image) {
        setKidsImage(DesktopKids)
        setKidsHover(false)
    }
   

    return (
        <div>
            <h3 style={{
                marginTop: "30px",
                marginBottom: "20px",
                textAlign: "center"
            }}>
                <span style={{
                    color: "#222222",
                    fontSize: "2rem",
                    fontWeight: 600,
                    lineHeight: "30px",
                    textTransform: "uppercase",
                    borderBottom: " 1.5px solid grey"
                }}>
                    Shop By Category
                </span>

            </h3>
            <div className='row' style={{ margin: 0, height: "auto" }}>

                <div className='col-12 col-sm-12 col-md-12 col-lg-8' style={{ margin: 0, padding: 0 }}>

                    <picture onClick={() => {
                        navigate("/stitching-design/Man")
                    }}
                        onMouseOver={MouseOverMan}
                        onMouseOut={MouseOutMan}
                        className='mb-4'  >
                        <source
                            media="(max-width: 768px)"
                            srcSet={`${MobileMan} 768w`}
                            sizes="768px"
                            width={"100%"}
                        // height={"100%"}
                        // className="bg-image"
                        />
                        <source
                            srcSet={`${manhovered ? DesktopManHover : DesktopMan} 1280w`}
                            sizes="1280px"
                            width={"100%"}
                            height={"350vh"}

                            style={{
                                borderRadius: 200
                            }}

                        />
                        <img src={menImage}
                        />
                    </picture>


                    <picture onClick={() => {
                        navigate("/stitching-design/Women")
                    }}
                        onMouseOver={MouseOverWomen}
                        onMouseOut={MouseOutWomen}
                        className=' mt-3'  >
                        <source
                            media="(max-width: 768px)"
                            srcSet={`${MobileWomen} 768w`}
                            sizes="768px"
                            width={"100%"}
                        // height={250}
                        />
                        <source
                            srcSet={`${hovered ? DesktopWomenHover : DesktopWomen} 1280w`}
                            sizes="1280px"
                            width={"100%"}
                            height={"300vh"}
                            style={{
                                borderRadius: 200
                            }}

                        />
                        <img src={MobileWomen}

                        />
                    </picture>
                </div>

                <div className='col-12 col-sm-12 col-md-12 col-lg-4' style={{ margin: 0, padding: 0 }}>


                    <picture onClick={() => {
                        navigate("/stitching-design/Kids")
                    }}
                        onMouseOver={MouseOver}
                        onMouseOut={MouseOut}
                    >
                        <source
                            media="(max-width: 768px)"
                            srcSet={`${MobileKids} 768w`}
                            sizes="768px"
                            width={"100%"}
                            height={250}
                        />
                        <source
                            srcSet={`${kidsHover ? DesktopKidsHover : DesktopKids} 1280w`}
                            sizes="1280px"
                            width={"100%"}
                            height={"100%"}
                            style={{
                                borderRadius: 200
                            }}

                        />
                        <img src={MobileKids}
                        />
                    </picture>

                </div>

            </div>

        </div>
    )
}

export default Design