import React from 'react'
import { faqs } from '../Utilis/FAQSQuestion'
import { setTitleAndDescription } from '../SEO/SeoFile'
function FAQ() {

    setTitleAndDescription("FashionWise Questions", `You will get every your confusion answer here. We are best in the
town for providing stitching services.`, `question about fashionwise ,
FAQ , online stitching service in lahore , best tailor in lahore , thefashionwise.com , fashionwise`)


    return (
        <div className='text-center mt-3'>
            <h1 className='text-uppercase fw-bold fs-20 bg-light '>
                Faqs
            </h1>
            <div className="accordion accordion-flush container" id="accordionFlushExample">
                {
                    faqs.map((item, index) => (
                        <div className="accordion-item mt-2">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed text-capitalize" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse${index}`} aria-expanded="false" aria-controls={`flush-collapse${index}`}>
                                    <b>{item.question} </b>
                                </button>
                            </h2>
                            <div id={`flush-collapse${index}`} className="accordion-collapse collapse" data-bs-parent="#accordionFlushExample">
                                <div className="accordion-body text-start">

                                    <p className='text-center text-capitalize'>
                                        {
                                            item.shortAnswer
                                        }
                                    </p>
                                    {
                                        item.answer.map((q) => (
                                            <p className='mt-1 text-capitalize'>
                                                <strong className='ps-2 pe-2'>{q.heading}</strong>
                                                {q.text}
                                            </p>
                                        ))
                                    }

                                </div>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}

export default FAQ